import { Redirect } from 'react-router-dom';
import React from 'react';
import { useAuth } from '../../shared/auth/Auth.context';
import { ROUTES } from '../../shared/constants';

export const Shop = () => {
    const { session_id, sessionError } = useAuth();

    if (sessionError || !session_id) {
        return <Redirect to={ROUTES.client.errorPage} />;
    }

    return <Redirect to={ROUTES.client.catalog} />;
};
