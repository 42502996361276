import React, { useMemo, useRef } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as QrIcon } from '../../shared/images/icons/qr.svg';
import { CatalogBuyContainer } from '../../New/features/catalog/components';
import CloseButton from '../../New/shared-components/ui/Buttons/CloseButton';
import { LangDropdown } from '../../New/shared-components/ui/Dropdowns/LangDropdown';
import common from '../../shared/styles/common.module.scss';
import {
    getCatalogReload,
    getFilterStateFromLocalStorage,
    getMonoCatalog,
    getShowScannerInCatalog,
    setCatalogReload,
} from '../../shared/utils/storage';
import Filter from './Filter';
import { FilterMonoCatalog } from './FilterMonoCatalog';
import { ListCategories } from './ListCategories';
import s from './styles.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';

import GridDynamic from './DynamicGrid';
import PercentageLoader from '../../New/shared-components/ui/Loaders/PercentageLoader';
import { graphqlClientFront, LinkType } from '../../shared/graphql/clients';
import { useGetCartQuery } from '../../shared/graphql/client/useGetCartQuery';
import { ROUTES } from '../../shared/constants';
import { useAuth } from '../../shared/auth/Auth.context';
import {
    GET_CATALOG_BY_FILTER,
    GET_FILTER_CATEGORIES,
} from '../../shared/graphql/client/graphql';

export const sizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];

export type FilterState = {
    male: boolean;
    female: boolean;
    size: string[];
};

export type AsconaFilter = {
    category: string[];
    number: string[];
    size: string[];
    options: string[];
};

const initialFilterState = {
    male: true,
    female: true,
    size: [],
};

const defaultMonoCatalogFilter = {
    category: 'Mattress',
    number: '',
    size: '',
    options: '',
};

export const CatalogNew = () => {
    const { session_id } = useAuth();
    const [retryCount, setRetryCount] = useState(0);

    const shopId = localStorage.getItem('shop_id');
    const { t } = useTranslation();
    const history = useHistory();
    const client = useApolloClient();
    const isMonoCatalog = getMonoCatalog();

    useEffect(() => {
        if (!session_id) {
            history.push(ROUTES.client.root);
        }
        return () => {
            const body = document.querySelector('body');
            if (body) {
                body.style.overflow = 'auto';
            }
        };
    }, []);

    const sizesInCategories = true;
    const [filterState, setFilterState] =
        useState<FilterState>(initialFilterState);

    const [asconaFilterState, setAsconaFilterState] = useState(
        isMonoCatalog
            ? getFilterStateFromLocalStorage(shopId) || defaultMonoCatalogFilter
            : defaultMonoCatalogFilter
    );

    const nextPage = useRef<number>(1);

    const { data: categories, loading: categoriesLoading } = useQuery(
        GET_FILTER_CATEGORIES,
        {
            context: { linkType: LinkType.Client },
            client: graphqlClientFront,
        }
    );

    useEffect(() => {
        if (isMonoCatalog) {
            const filter = getFilterStateFromLocalStorage(shopId);
            if (filter) setAsconaFilterState(filter);
        }
    }, [isMonoCatalog]);

    const { cart, cartLoading } = useGetCartQuery();

    const {
        error,
        data: onFilterDataChange,
        loading: filterDataLoading,
        fetchMore,
        refetch: refetchFilterQuery,
    } = useQuery(GET_CATALOG_BY_FILTER, {
        errorPolicy: 'all',
        onCompleted: () => {
            setRetryCount(0);
        },
        context: { linkType: LinkType.Client },
        client: graphqlClientFront,
        variables: {
            number: asconaFilterState.number,
            category: asconaFilterState.category,
            size: asconaFilterState.size,
            option: asconaFilterState.options,
            page: 1,
            limit: 50,
        },
        skip: !isMonoCatalog,
    });

    useEffect(() => {
        if (error && retryCount < 3) {
            const timer = setTimeout(() => {
                refetchFilterQuery();
                setRetryCount(retryCount + 1);
            }, 1000 * retryCount);

            return () => clearTimeout(timer);
        }
        if (retryCount >= 3 && error) {
            console.error('Max retries reached with error:', error.message);
        }
    }, [error, retryCount, refetchFilterQuery]);

    useEffect(() => {
        const needUpd = getCatalogReload();

        if (needUpd) {
            client.cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'aggregatedProducts',
                broadcast: true,
            });
            isMonoCatalog && refetchFilterQuery();
            setCatalogReload(false);
        }
    }, [isMonoCatalog]);

    const monoCatalogItems = useMemo(() => {
        return !!onFilterDataChange?.aggregatedProducts?.list?.length &&
            !filterDataLoading
            ? onFilterDataChange?.aggregatedProducts?.list
            : [];
    }, [filterDataLoading, onFilterDataChange, asconaFilterState]);

    const [infiniteLength, setInfiniteLength] = useState(0);

    const onLoadMore = async (page: number) => {
        fetchMore({
            variables: { page },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;

                const newResultsList = [
                    ...prev.aggregatedProducts.list,
                    ...fetchMoreResult.aggregatedProducts.list,
                ];
                setTimeout(() => setInfiniteLength(newResultsList.length), 1);
                return Object.assign({}, prev, {
                    aggregatedProducts: {
                        ...prev.aggregatedProducts,
                        ...fetchMoreResult.aggregatedProducts,
                        list: newResultsList,
                    },
                });
            },
        });
    };

    const parentRef = React.useRef<HTMLDivElement>(null);

    const [openedCategory, setOpenedCategory] = useState<string>();

    const itemsLength = useMemo(() => {
        return monoCatalogItems.length ?? 0;
    }, [monoCatalogItems.length]);

    const hasMore = useMemo(() => {
        return (
            monoCatalogItems?.length <
            onFilterDataChange?.aggregatedProducts?.pagination?.total
        );
    }, [
        onFilterDataChange?.aggregatedProducts?.pagination?.total,
        monoCatalogItems?.length,
    ]);

    const categoriesContainerRef = useRef<HTMLDivElement>(null);

    const onCategoryOpen = () => {
        const body = document.querySelector('body');
        if (categoriesContainerRef.current && body) {
            body.style.overflow = 'hidden';
            categoriesContainerRef.current.style.overflow = 'hidden';
        }
    };

    const onCategoryClose = () => {
        const body = document.querySelector('body');
        if (categoriesContainerRef.current && body) {
            body.style.overflow = 'auto';
            categoriesContainerRef.current.style.overflow = 'auto';
        }
    };

    const withFooter = Number(cart?.cartProducts?.length || 0) > 0;

    return categoriesLoading || cartLoading ? (
        <div className={common.LoadingCover} style={{ display: 'flex' }}>
            <PercentageLoader />
        </div>
    ) : (
        <div className={s.catalog__container}>
            {!openedCategory ? (
                <div className={clsx(s.Header)}>
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: isMonoCatalog ? 0 : 20,
                            minHeight: 30,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                        >
                            <span>{t('29_Catalog')}</span>

                            {getShowScannerInCatalog() && !isMonoCatalog && (
                                <button
                                    className={s.Header_qrButton}
                                    onClick={() =>
                                        history.push(ROUTES.client.scanCode)
                                    }
                                >
                                    <QrIcon />
                                </button>
                            )}
                        </div>
                        {cart?.cartProducts &&
                        cart?.cartProducts?.length > 0 ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '16px',
                                }}
                            >
                                <LangDropdown />
                                <div
                                    style={{
                                        padding: '10px',
                                        paddingRight: 0,
                                        paddingLeft: 0,
                                    }}
                                >
                                    <CloseButton
                                        onClose={() => history.goBack()}
                                    />
                                </div>
                            </div>
                        ) : (
                            <LangDropdown />
                        )}
                    </div>
                </div>
            ) : null}
            <div
                ref={categoriesContainerRef}
                className={clsx(
                    s.CategoriesContainer,
                    s['catalog__catalog-items-container'],
                    {
                        [s.CategoriesContainer_noTopFilter]: sizesInCategories,
                        [s.CategoriesContainer_withoutFilter]: isMonoCatalog,
                    }
                )}
            >
                {isMonoCatalog ? (
                    <>
                        <FilterMonoCatalog
                            categories={
                                categories?.aggregatedProducts?.categories ?? []
                            }
                            categoriesLoading={categoriesLoading}
                            filterState={asconaFilterState}
                            setFilterState={setAsconaFilterState}
                        />
                        {filterDataLoading ? (
                            <PercentageLoader />
                        ) : (
                            <InfiniteScroll
                                className={s.MonoCategory}
                                dataLength={infiniteLength}
                                next={() => {
                                    nextPage.current = nextPage.current + 1;
                                    onLoadMore(nextPage.current);
                                }}
                                hasMore={hasMore}
                                style={{
                                    overflow: 'unset',
                                }}
                                loader={<PercentageLoader />}
                                scrollableTarget="scrollable"
                                scrollThreshold={1}
                            >
                                <GridDynamic
                                    ref={parentRef}
                                    items={monoCatalogItems}
                                    total={itemsLength}
                                    cartLevel={cart?.level}
                                    cartId={cart?.id ?? ''}
                                />
                            </InfiniteScroll>
                        )}
                    </>
                ) : (
                    <>
                        {!openedCategory ? (
                            <Filter
                                filterState={filterState}
                                setFilterState={setFilterState}
                                showSizes={!sizesInCategories}
                            />
                        ) : null}
                        <ListCategories
                            withFooter={withFooter}
                            openedCategory={openedCategory}
                            setOpenedCategory={setOpenedCategory}
                            onClose={onCategoryClose}
                            onOpen={onCategoryOpen}
                            setFilterState={setFilterState}
                            categories={
                                categories?.aggregatedProducts?.categories ?? []
                            }
                            cart={cart}
                            filterState={filterState}
                            sizesInCategories={sizesInCategories}
                            withoutFilter={isMonoCatalog}
                        />
                    </>
                )}
            </div>
            {withFooter ? <CatalogBuyContainer cart={cart} /> : null}
        </div>
    );
};
