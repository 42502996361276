export const ROUTES = {
    client: {
        root: '/',
        errorPage: '/errorPage',
        qrcode: '/qrcode',
        scanCode: '/scan_code',
        productId: '/product/:id',
        cartId: '/cart/:cartId',
        shopId: '/shop/:shopId',
        catalog: '/catalog',
    },
    seller: {
        sellerAuth: '/seller/auth',
        seller: '/seller',
    },
    admin: {
        signIn: '/sign-in',
        itemsAdmin: '/axaxax36ax/items-admin',
        statisticsSalesSummary: '/axaxax36ax/statistics/sales_summary',
        statisticsProductRatings: '/axaxax36ax/statistics/product_ratings',
    },
    superAdmin: {
        superAdminAuth: '/superadmin/auth',
        superAdminShopId: '/superadmin/shops/:shopId',
        superAdmin: '/superadmin',
    },
};
