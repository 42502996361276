import axios from 'axios';
import { getToken } from './shared/utils/token';

const instanceAdmin = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
});

instanceAdmin.interceptors.request.use(
    function (config: any) {
        const token = getToken();
        if (token) {
            config.headers['Token'] = getToken();
        }
        return config;
    },
    function (error: any) {
        return Promise.reject(error);
    }
);

export const api = {
    login: (data: { username: string; password: string }) =>
        instanceAdmin.post(`/shop-admin/login`, data),
    shop_admin: () => instanceAdmin.get(`/shop-admin/product`),
    shop_admin_product: (aggregation_sign) =>
        instanceAdmin.get(`/shop-admin/product/${aggregation_sign}`),
    shop_admin_patch: (
        aggregation_sign: string,
        data: {
            max_discount?: number;
            exception?: boolean;
            img_link?: string;
            sell_up_before?: string;
        }
    ) => instanceAdmin.patch(`/shop-admin/product/${aggregation_sign}`, data),
    shop_admin_report_sales_summary: (filter: {
        dateFrom: string;
        dateTo: string;
        seasons: string;
    }) =>
        instanceAdmin.get(`/shop-admin/report/sales_summary`, {
            params: filter,
        }),
    shop_admin_report_collections_stocks_percentage: (filter: {
        dateFrom: string;
        dateTo: string;
        seasons: string;
    }) => instanceAdmin.get(`/shop-admin/report/collections_stocks_percentage`),
    shop_admin_report_get_seasons: () =>
        instanceAdmin.get(`/shop-admin/report/seasons`),
    shop_admin_report_product_ratings: () =>
        instanceAdmin.get(`/shop-admin/report/product_ratings`),
};
