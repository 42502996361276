import React, { FC } from 'react';
import { Table } from 'antd';

interface Props {
    items: any[];
    isLoading: boolean;
}

export const StocksCollections: FC<Props> = ({ items, isLoading }) => {
    const columns = [
        {
            title: 'Коллекция',
            dataIndex: 'collection',
        },
        {
            title: 'stock_now_шт',
            dataIndex: 'stock_now_cnt',
            render: (value, data) =>
                `${value} (${data.stock_now_cnt_max_percents}%)`,
        },
        {
            title: 'stock_now_руб',
            dataIndex: 'stock_now_sum',
            render: (value, data) =>
                `${value} (${data.stock_now_sum_max_percents}%)`,
        },
        // {
        //     title: 'stock_max_шт',
        //     dataIndex: 'stock_now_cnt_max_percents',
        //     render: (value, data) =>
        //         data.stock_now_cnt && value
        //             ? parseFloat(
        //                   ((data.stock_now_cnt * 100) / value).toFixed(2)
        //               )
        //             : '-',
        // },
        // {
        //     title: 'stock_max_руб',
        //     dataIndex: 'stock_now_sum_max_percents',
        //     render: (value, data) =>
        //         data.stock_now_sum && value
        //             ? parseFloat(
        //                   ((data.stock_now_sum * 100) / value).toFixed(2)
        //               )
        //             : '-',
        // },
    ];

    return (
        <Table
            pagination={false}
            loading={isLoading}
            columns={columns}
            dataSource={items}
        />
    );
};
