export const getNoun = (number, one, two, five) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
};

export default function numberWithSpaces(value?: number | string): string {
    if (!value?.toString()) {
        return '';
    }
    const number = Number(value.toString().replace(/\s/g, ''));
    return new Intl.NumberFormat('ru-RU').format(number / 100).toString();
}
