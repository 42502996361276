import React, { useEffect, useState } from 'react';
import Accordion from '../../UiComponents/Accordion';
import { useQuery } from '@apollo/client';
import CategoryContent, { FilterState } from './CategoryContent';
import { saveCategoryStateToLocalStorage } from '../../shared/utils/storage';
import { graphqlClientFront, LinkType } from '../../shared/graphql/clients';
import { GET_SIZES_BY_CATEGORY } from '../../shared/graphql/client/graphql';

interface Props {
    title: string;
    level: number;
    sizeArr: string[];
    withTopFilter: boolean;
    filterState: FilterState;
    isOpened: boolean;
    onSetOpenedCategory: () => void;
    cartId: string;
    onOpen: () => void;
    withFooter?: boolean;
}

const Category = ({
    title,
    withTopFilter,
    filterState,
    isOpened,
    level,
    sizeArr,
    onSetOpenedCategory,
    cartId,
    onOpen,
    withFooter,
}: Props) => {
    const shopId = localStorage.getItem('shop_id');

    const [size, setSize] = useState<string[]>(sizeArr || []);

    const { data: sizesData, loading: sizeLoading } = useQuery(
        GET_SIZES_BY_CATEGORY,
        {
            context: { linkType: LinkType.Client },
            client: graphqlClientFront,
            variables: {
                category: title,
            },
            skip: !isOpened,
        }
    );

    useEffect(() => {
        if (isOpened && filterState) {
            saveCategoryStateToLocalStorage(shopId, {
                ...filterState,
                size,
                category: title,
            });
        }
    }, [isOpened, filterState, size]);

    const sizes = sizesData?.aggregatedProducts?.sizes?.length
        ? [...sizesData?.aggregatedProducts?.sizes]
        : [];

    return (
        <Accordion
            withFooter={withFooter}
            title={title}
            isOpen={!!isOpened}
            setIsOpen={() => onSetOpenedCategory()}
            withTopFilter={withTopFilter}
            filter={{
                sizes: sizes,
                state: {
                    ...filterState,
                    size,
                },
                onSelect: (selectedSize) =>
                    setSize(
                        size.includes(selectedSize)
                            ? size.filter((item) => item !== selectedSize)
                            : [...size, selectedSize]
                    ),
            }}
        >
            {isOpened ? (
                <CategoryContent
                    withFooter={withFooter}
                    onOpen={onOpen}
                    cartId={cartId}
                    sizeLoading={sizeLoading}
                    title={title}
                    level={level}
                    size={size}
                    female={filterState.female}
                    male={filterState.male}
                />
            ) : null}
        </Accordion>
    );
};

export default Category;
