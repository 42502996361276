import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        pointsRequired: 0,
        pointsTargetProduct: null,
        pointsProducts: [],
        cartOfferState: null,
        isLoading: false,
        isShowHint: false,
    },
    reducers: {
        setData: (state, action: PayloadAction<any>) => {
            return action.payload;
        },
        clearData: (state) => ({
            pointsRequired: 0,
            pointsTargetProduct: null,
            isLoading: state.isLoading,
            pointsProducts: [],
            cartOfferState: null,
            isShowHint: false,
        }),
        setPointsRequired: (state, action) => ({
            ...state,
            pointsRequired: action.payload,
        }),
        setPointsTargetProduct: (state, action) => ({
            ...state,
            pointsTargetProduct: action.payload,
        }),
        setPointsProducts: (state, action) => ({
            ...state,
            pointsProducts: action.payload,
        }),
        setIsLoading: (state, action) => ({
            ...state,
            isLoading: action.payload,
        }),
        setIsShowHint: (state, action) => ({
            ...state,
            isShowHint: action.payload,
        }),
        setCartOfferState: (state, action) => ({
            ...state,
            cartOfferState: action.payload,
        }),
    },
});

export const {
    setData,
    clearData,
    setPointsRequired,
    setPointsTargetProduct,
    setPointsProducts,
    setIsLoading,
    setIsShowHint,
    setCartOfferState,
} = appSlice.actions;

export default appSlice.reducer;
