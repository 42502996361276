import React from 'react';
import styles from './styles.module.scss';
import { normalizeSizes } from '../../../../../shared/utils/normalizeSizes';

const ProductSizes = ({ sizes }: { sizes: string[] }) => {
    return (
        <div className={styles.info__sizes}>
            {normalizeSizes(sizes).map((size) => (
                <div key={size}>{size}</div>
            ))}
        </div>
    );
};

export default ProductSizes;
