import { gql } from '@apollo/client';

export const TRADE = gql`
    mutation trade($id: String!, $value: Int!) {
        trade(id: $id, value: $value) {
            cartProducts {
                color
                current
                packPrice
                count
                inCondition
                displayPrice
                id
                imageUrl
                number
                price
                sizes
                countTotal
                priceCross
            }
            id
            level
            attempts
            offerState
            tradeComplete
            betterOffer
            goodOffer
            availableAttempts
            targetLevel
        }
    }
`;
