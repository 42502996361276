import React from 'react';
import numberWithSpaces from '../../shared/utils/utils';

import styles from './styles.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getClientCurrency } from '../../shared/utils/storage';

export type PriceView = 'crossed' | 'green' | 'orange' | undefined;

interface Props {
    children: number;
    type?: PriceView;
    className?: string;
    bold?: boolean;
}

const PriceString = ({ children, type, className, bold }: Props) => {
    const { t } = useTranslation();
    return children?.toString() ? (
        <div
            className={clsx(styles.Price, className, {
                [styles.Price_green]: type === 'green',
                [styles.Price_orange]: type === 'orange',
                [styles.Price_crossed]: type === 'crossed',
                [styles.Price_bold]: !!bold,
            })}
        >{`${numberWithSpaces(children)} ${getClientCurrency()}`}</div>
    ) : (
        <></>
    );
};

export default PriceString;
