import { getPrice } from './CartsPage';

export const maximumProductsPricesLength = (eventsData?: any[]) =>
    eventsData?.reduce((acc, step) => {
        const pricesLength = step.productsMetrics?.reduce(
            (priceLength, metric) => {
                return priceLength + getPrice(metric.displayPrice).length * 40;
            },
            0
        );
        if (pricesLength > acc) {
            return pricesLength;
        }
        return acc;
    }, 0);

export const cellsCount = (eventsData?: any[]) => {
    let count = 0;
    eventsData?.forEach((step) => {
        if (step.productsMetrics?.length > count) {
            count = step.productsMetrics?.length;
        }
    });
    return count;
};

export const parseAttempt = (actionParams: string) => {
    return actionParams ? getPrice(JSON.parse(actionParams)?.Value) : null;
};
