import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import { changeLanguage } from 'i18next';
import { LanguagesType, useI18n } from '../../../../../shared/i18n';

const LanguageMap = new Map([
    ['ru', 'RUS'],
    ['en', 'ENG'],
    ['el', 'GREEK'],
    ['es', 'ES'],
]);

export const LangDropdown: React.FC = () => {
    const { setLanguage, getLanguage, getLanguages } = useI18n();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const langs = getLanguages();
    const existingLangsMap = new Map<string, string>(
        langs.map((lang) => [lang, LanguageMap.get(lang) || ''])
    );
    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener for the click event
        document.addEventListener('mousedown', handleClickOutside);
        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.dropdown} ref={dropdownRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className={styles.dropdownButton}
            >
                {existingLangsMap.get(getLanguage())}
            </button>
            {isOpen && (
                <div
                    className={`${styles.dropdownContent} ${
                        isOpen ? styles.show : ''
                    }`}
                >
                    {Array.from(existingLangsMap).map(([key, value]) => (
                        <a
                            key={key}
                            href="#"
                            onClick={() => {
                                changeLanguage(key);
                                setLanguage(key as LanguagesType);
                                setIsOpen(false);
                            }}
                        >
                            {value}
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
};
