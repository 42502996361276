function getRussianPluralKey(count) {
    if (count % 10 === 1 && count % 100 !== 11) {
        return ''; // Singular form
    } else if (
        [2, 3, 4].includes(count % 10) &&
        ![12, 13, 14].includes(count % 100)
    ) {
        return '_2'; // Dual form
    } else {
        return '_5'; // Plural form
    }
}

export default getRussianPluralKey;
