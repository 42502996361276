import React from 'react';

export const SuperAdminLayout: React.FC = ({ children }) => (
    <>
        <div
            style={{ display: 'none' }}
            data-test-id="super-admin-layout"
        ></div>
        {children}
    </>
);
