import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    AuthClientSessionRequestInterface,
    AuthClientSessionResponseInterface,
    AuthClientSessionRouteParamsInterface,
} from '../Auth.types';
import {
    AsconaId,
    AsconaId2Error,
    AsconaId2Ok,
    getSellerSession,
    removeAsconaSession,
    removeClientSession,
    setAsconaSession,
    setClientCurrency,
    setClientSession,
    setMonoCatalog,
    setShowPriceCross,
    setShowScannerInCatalog,
} from '../../utils/storage';
import { languageFormatter } from '../../utils/languageFormatter';
import { LanguagesType, useI18n } from '../../i18n';
import { ROUTES } from '../../constants';
import { getAxiosError } from '../../errors/axiosErrorHandler';
import { notification } from 'antd';
import { clientSession } from '../utils/session';
import * as Sentry from '@sentry/react';

const cartSession = {
    set: (cart_id: string) => {
        localStorage.setItem('cart_id', cart_id);
    },
    get: () => {
        return localStorage.getItem('cart_id');
    },
    remove: () => {
        localStorage.removeItem('cart_id');
    },
};

export const useClientSessionRequest = () => {
    const {
        id: product_id,
        shopId: shop_id,
        cartId: cart_id,
    } = useParams<AuthClientSessionRouteParamsInterface>();
    const history = useHistory();

    const { i18n, setLanguage, setLanguages, getLanguages } = useI18n();

    const [isSessionLoading, setIsSessionLoading] = useState<boolean>(true);
    const [sessionError, setSessionError] = useState<any>(null);
    const [session, setSession] =
        useState<AuthClientSessionResponseInterface | null>(null);

    const loadClientSession = useCallback(async () => {
        setIsSessionLoading(true);

        /**
         * Если нет одного из параметров, то ничего не делаем,
         * так как запрос за сессиями работает только с одним из этих 3-х параметров
         */
        if (!product_id && !shop_id && !cart_id) {
            setIsSessionLoading(false);

            return;
        }

        removeClientSession();
        removeAsconaSession();
        clientSession.remove();

        const requestBody = {};

        if (product_id) {
            requestBody['product_id'] = product_id;
        }

        if (shop_id) {
            requestBody['shop_id'] = shop_id;
        }

        if (cart_id) {
            requestBody['cart_id'] = cart_id;
            cartSession.set(cart_id);
        }

        try {
            const data = await axios.post<
                AuthClientSessionRequestInterface,
                AxiosResponse<AuthClientSessionResponseInterface>
            >(`${process.env.REACT_APP_API_PATH}session`, requestBody);

            if (
                data.data.shop_id == AsconaId ||
                data.data.shop_id == AsconaId2Error ||
                data.data.shop_id == AsconaId2Ok
            ) {
                setAsconaSession(data.data.shop_id);
            }

            const languages = data.data.languages?.map((l) =>
                languageFormatter(l)
            );
            setShowScannerInCatalog(Boolean(data.data?.should_show_scanner));
            setMonoCatalog(data.data.use_mono_catalog);
            setShowPriceCross(data.data.should_show_price_cross);
            setClientCurrency(data.data.currency);

            setLanguage(languages[0] as LanguagesType);
            setLanguages(languages as LanguagesType[]);
            void i18n.changeLanguage(languages[0]);

            await setClientSession(data.data.session_id);

            localStorage.setItem('shop_id', data.data.shop_id);

            await clientSession.set(data.data);
            setSession(data.data);
            Sentry.setUser({ id: session?.session_id, sessionExists: false });
        } catch (error) {
            setSessionError(error);
            Sentry.setUser({ id: 'error', sessionExists: false });

            const error_ = getAxiosError(error);
            Sentry.captureException(error);

            if (error_.type !== 'COMMON') {
                notification.error({
                    message: error_.message,
                });

                return;
            }

            if (cart_id) {
                notification.error({
                    message: 'Order not found',
                });

                return;
            }

            if (product_id) {
                history.push(
                    ROUTES.client.productId.replace(':id', product_id)
                );
            }
        } finally {
            setIsSessionLoading(false);
        }
    }, [product_id, shop_id, cart_id, setIsSessionLoading]);

    const isSessionExist = () => {
        const session = clientSession.get();

        if (!session) {
            return false;
        }

        /**
         * Если магазин в сессии и запрашиваемый магазином (в query params) не совпадают,
         * надо запросить новую сессию
         */
        if (shop_id && session.shop_id !== shop_id) {
            return false;
        }

        /**
         * Если передан cart_id, то создаем новую сессию
         * - если корзины не совпадают и нет сессии Seller
         * - если не сессия Seller
         */
        if (cart_id && cartSession.get() !== cart_id && !getSellerSession()) {
            return false;
        } else if (getSellerSession()) {
            // TODO: remove after fixing seller sessions https://trello.com/c/KVYiwdiR
            return true;
        }

        return new Date(session.expires_at) > new Date();
    };

    /**
     * Инициализируем сессию только при открытии ROUTES.client.* страниц
     * - Открытие в браузере через строку адреса
     * - Переход из админок
     *
     * При переходах между ROUTES.client.* страницами, сессия не инициализируется
     */
    useEffect(() => {
        if (isSessionExist()) {
            const session = clientSession.get();

            setSession(session);
            setIsSessionLoading(false);
            Sentry.setUser({ id: session?.session_id, sessionExists: true });
        } else {
            void loadClientSession();
        }
    }, []);

    return {
        isSessionLoading,
        setIsSessionLoading,
        sessionError,
        setSessionError,
        setSession,
        session,
        loadClientSession,
    };
};
