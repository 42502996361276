import React, { forwardRef, RefObject } from 'react';
import { CatalogItem } from '../../New/features/catalog/components';
import { useGrid, Grid } from '../../packages/react/src';
import { AggregatedProduct } from '../../shared/graphql/client/generated/graphql.types';

interface GridDynamicProps {
    items: AggregatedProduct[]; // Replace 'any' with the type of 'items' array
    total: number;
    cartLevel?: number;
    cartId: string;
}

const GridDynamic = forwardRef<HTMLElement, GridDynamicProps>(
    ({ items, total, cartLevel, cartId }, ref) => {
        const grid = useGrid({
            scrollRef: ref as RefObject<HTMLElement>, // Add type assertion
            count: total,
            size: { width: 156, height: 351 },
            columns: 'auto',
        });

        return (
            <Grid grid={grid} items={items}>
                {(i) => {
                    const product = items[i];
                    if (!product) return null;

                    return (
                        <CatalogItem
                            key={product.aggregationSign}
                            product={product}
                            level={cartLevel ?? 0}
                            cartId={cartId}
                        />
                    );
                }}
            </Grid>
        );
    }
);

export default GridDynamic;
