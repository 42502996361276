import React from 'react';
import { OfferState } from '../../../../services/graphql';
import ProductItem from '../product-item';
import styles from './styles.module.scss';
import type { CartProduct } from '../../../../../shared/graphql/client/generated/graphql.types';

type Props = {
    products: CartProduct[];
    showHintAfterTrade: boolean;
    offerState?: OfferState;
    targetLevel: number;
};

const ProductList = ({
    products,
    showHintAfterTrade,
    offerState,
    targetLevel,
}: Props) => {
    const sortedProducts = [...products].sort((a, b) => {
        if (a.current) return -1;
        if (b.current) return 1;
        return 0;
    });
    return (
        <div className={styles.container}>
            {sortedProducts.map((product) => (
                <div className={styles['item-contanier']} key={product.id}>
                    <ProductItem
                        offerState={offerState}
                        targetLevel={targetLevel}
                        showHintAfterTrade={Boolean(
                            showHintAfterTrade && product.current
                        )}
                        product={product}
                    />
                </div>
            ))}
        </div>
    );
};

export default ProductList;
