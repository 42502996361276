export function setToken(token: string) {
    localStorage.setItem('batnaToken', token);
}

export function getToken() {
    return localStorage.getItem('batnaToken');
}

export const logout = () => {
    localStorage.setItem('isAuthenticated', '');
    localStorage.setItem('batnaToken', '');
    localStorage.setItem('shop_id', '');
    window.location.pathname = '/sign-in';
};
