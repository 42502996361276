import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import common from '../../../shared/styles/common.module.scss';
import {
    ArrowLeftOutlined,
    TrophyOutlined,
    ShoppingCartOutlined,
    ShoppingOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import { Button, Layout } from 'antd';
import s from '../styles.module.scss';
import ResultsPage from './ResultsPage';
import CartsPage from './CartsPage';
import { removeAdminSession } from '../../../shared/utils/storage';
import PercentageLoader from '../../../New/shared-components/ui/Loaders/PercentageLoader';
import {
    graphqlClientSuperAdmin,
    LinkType,
} from '../../../shared/graphql/clients';
import { ROUTES } from '../../../shared/constants';
import { ADMIN_GET_SHOPS } from '../../../shared/graphql/superadmin/graphql';
import {
    GetShopsQuery,
    GetShopsQueryVariables,
} from '../../../shared/graphql/superadmin/generated/graphql.types';

enum Pages {
    Results = 'results',
    Carts = 'carts',
    Products = 'products',
}

const Shop = () => {
    const options = {
        context: { linkType: LinkType.SuperAdmin },
        client: graphqlClientSuperAdmin,
    };
    const { shopId } = useParams<{ shopId: string }>();
    const history = useHistory();

    const { loading, data } = useQuery<GetShopsQuery, GetShopsQueryVariables>(
        ADMIN_GET_SHOPS,
        options
    );

    const [page, setPage] = useState(Pages.Results);

    if (loading) {
        return (
            <div
                className={common.LoadingCover}
                style={{ display: loading ? 'flex' : 'none' }}
            >
                <PercentageLoader />
            </div>
        );
    }

    const shop = data?.shops?.find((shop) => shop.id === shopId);

    const { Header, Content } = Layout;

    return (
        <Layout className={common.AdminPage} style={{ padding: 0 }}>
            <Header className={s.Shop__header}>
                <Button
                    type="text"
                    icon={<ArrowLeftOutlined />}
                    size="large"
                    onClick={() => history.goBack()}
                />
                <span>{shop?.title}</span>
                <Button
                    type="text"
                    style={
                        page === Pages.Results
                            ? { borderBottom: '1px solid #0e0e0e' }
                            : {}
                    }
                    icon={<TrophyOutlined />}
                    size="large"
                    onClick={() => setPage(Pages.Results)}
                >
                    Результаты
                </Button>
                <Button
                    type="text"
                    style={
                        page === Pages.Carts
                            ? { borderBottom: '1px solid #0e0e0e' }
                            : {}
                    }
                    icon={<ShoppingCartOutlined />}
                    size="large"
                    onClick={() => setPage(Pages.Carts)}
                >
                    Корзины
                </Button>
                <Button
                    type="text"
                    style={
                        page === Pages.Products
                            ? { borderBottom: '1px solid #0e0e0e' }
                            : {}
                    }
                    icon={<ShoppingOutlined />}
                    size="large"
                    onClick={() => setPage(Pages.Products)}
                >
                    Товары
                </Button>

                <Button
                    type="text"
                    icon={<LogoutOutlined />}
                    size="large"
                    onClick={() => {
                        removeAdminSession();
                        history.push(ROUTES.superAdmin.superAdminAuth);
                    }}
                >
                    Выход
                </Button>
            </Header>
            <Content className={s.Shop__content}>
                {page === Pages.Results && <ResultsPage />}
                {page === Pages.Carts && <CartsPage />}
            </Content>
        </Layout>
    );
};

export default Shop;
