import React, { useRef, useState } from 'react';
import styles from './styles.module.scss';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { t } from 'i18next';
import Warning from './Warning';
import classNames from 'classnames';
import { useGetCart } from '../../../../hooks/useGetCart';
import { useMutationSendTradeOffer } from '../../hooks/useMutationSendTradeOffer';
import MainButton from '../../../../shared-components/ui/Buttons/MainButton';
import { getClientCurrency } from '../../../../../shared/utils/storage';
import PercentageLoader from '../../../../shared-components/ui/Loaders/PercentageLoader';
import Button from '../../../../../UiComponents/Button';
import calculateCartPrices from '../../../../utils/calculateCartPrices';
import {
    graphqlClientFront,
    LinkType,
} from '../../../../../shared/graphql/clients';
import { CartProduct } from '../../../../../shared/graphql/client/generated/graphql.types';

type Inputs = {
    suggestPrice: string;
};

type Props = {
    product: CartProduct;
};

const TradeDialog = ({ product }: Props) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<Inputs>({
        mode: 'onSubmit',
    });

    const [mutateOnTrade, { loading }] = useMutationSendTradeOffer();

    const cart = useGetCart();
    const cartPrices = calculateCartPrices(cart);

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        await mutateOnTrade({
            variables: {
                id: product.id,
                value: Number(data.suggestPrice) * 100,
            },
            context: { linkType: LinkType.Client },
            client: graphqlClientFront,
        });

        reset();
    };

    const hintToHaveDiscount = <span>{t('4_HigherPriceHigherChance')}</span>;

    const currency = getClientCurrency();

    const getTradeStatus = () => {
        if (loading) {
            return {
                smile: '⏳',
                text_status: t('6_processingPrice'),
                hint: hintToHaveDiscount,
                button_text: t('7_comparingOffersWithOthers'),
            };
        }
        if (cart?.attempts?.length) {
            return {
                smile: '👎',
                text_status: t('2_insufficientOffer'),
                hint: hintToHaveDiscount,
                button_text: t('5_OK'),
            };
        }
        return {
            smile: '🤑',
            text_status: t('3_offerYourTradePrice'),
            hint: hintToHaveDiscount,
            button_text: t('5_OK'),
        };
    };

    const { smile, text_status, hint, button_text } = getTradeStatus();

    if (!product || !isDialogOpen) {
        return (
            <Button
                className={styles.tradeButton}
                small
                text={t('trade_btn_cta')}
                action={() => setIsDialogOpen(true)}
                type="black"
            />
        );
    }

    const attempt = (cart?.attempts || [])[(cart?.attempts || []).length - 1];

    return (
        <form
            className={styles.trade_dialog}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
        >
            <div className={styles.trade_dialog_container}>
                <div className={styles.trade_dialog_title}>
                    <div className={styles.trade_dialog_title_smile}>
                        {smile}
                    </div>
                    <div className={styles['trade_dialog_title_status-info']}>
                        {text_status}
                    </div>
                    <div className={styles.trade_dialog_title_info}>{hint}</div>
                </div>
                <div className={styles.trade_dialog_input_wrapper}>
                    <>
                        <input
                            disabled={loading}
                            className={classNames(styles.trade_dialog_input, {
                                [styles.trade_dialog_input_error]:
                                    errors.suggestPrice,
                            })}
                            type="number"
                            inputMode="decimal"
                            {...register('suggestPrice', {
                                required: `${t('26_setThePrice')}`,
                                max: {
                                    value: Number(cartPrices.price) / 100,
                                    message: `${t('27_PriceShouldBeLower')}`,
                                },
                                min: {
                                    value: attempt ? attempt / 100 : 1,
                                    message: `${t('28_PriceShouldBeHigher')} ${
                                        attempt ? attempt / 100 : 1
                                    } ${currency}`,
                                },
                            })}
                            min={1}
                            max={Number(product.price) / 100}
                        />

                        {loading ? (
                            <PercentageLoader />
                        ) : (
                            <span
                                className={
                                    styles['trade_dialog_input__currency']
                                }
                            >
                                {currency}
                            </span>
                        )}
                    </>
                    {cart?.availableAttempts === 1 && (
                        <div className={styles.warning}>
                            <Warning />
                            {t('9_lastTry')}
                        </div>
                    )}
                    <ErrorMessage
                        errors={errors}
                        name="suggestPrice"
                        render={({ message }) => (
                            <div className={styles.error_message}>
                                {message}
                            </div>
                        )}
                    />
                </div>
                <div className={styles['trade_dialog_button-wrapper']}>
                    <MainButton
                        disabled={loading}
                        text={button_text}
                        type="submit"
                        className={styles.trade_btn}
                    />
                </div>
            </div>
        </form>
    );
};

export default TradeDialog;
