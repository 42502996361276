import React, { FC } from 'react';
import { Popover, Table, Tag } from 'antd';
import {
    ActionColor,
    getFinalDisplayPrices,
    getFinalStep,
    getPrice,
} from './CartsPage';
import { cellsCount, maximumProductsPricesLength, parseAttempt } from './utils';

type ProductMetrics = {
    aggregationSign: string;
    bonus: number;
    count: number;
    displayPrice: number;
    existsInOrder: boolean;
    mD: number;
    price: number;
    threshold: number;
    __typename: string;
};

type ActionType = {
    actionName: string;
    actionParams: string;
    cartID: string;
    cartPrice: number;
    cartSize: number;
    checkGap: number;
    checkMD: number;
    date: string;
    deltaTime: number;
    discount: number;
    displayPriceLTPriceCount: number;
    gain: number;
    juke: boolean;
    orderID: string;
    productsInCartBeforeTrade: number;
    productsMetrics: ProductMetrics[];
    totalBonus: number;
    tradeDiscount: number;
};
interface Props {
    eventsData: ActionType[];
}

const actionDesc = {
    actionName: 'Название действия',
    displayPrice: 'Конечные цены',
    gain: 'gain',
    cartPrice: 'Сумма товаров в корзине',
    discount: 'Средняя скидка товаров корзины',
    deltaTime: 'Время в секундах с предыдущего действия в корзине',
};

export const CartEventsTable: FC<Props> = ({ eventsData }) => {
    const columns = (obj, fixed?: string[], width?: number) =>
        Object.keys(obj).map((key) => {
            let params: any = {
                title: () => (
                    <Popover placement={'bottomLeft'} content={obj[key]}>
                        <span style={{ width: '100%', display: 'block' }}>
                            {key}
                        </span>
                    </Popover>
                ),
                dataIndex: key,
                key,
                width: width || 100,
            };

            !['actionName', 'cartID', 'orderID', 'juke', 'date'].includes(
                key
            ) && (params = { ...params, sorter: (a, b) => a[key] - b[key] });
            fixed?.includes(key) && (params = { ...params, fixed: true });
            key === 'displayPrice' &&
                (params = {
                    ...params,
                    width: maximumProductsPricesLength(eventsData),
                    className: 'cell-green',
                });
            key === 'date' &&
                (params = {
                    ...params,
                    width: 180,
                    fixed: true,
                });
            key === 'gain' &&
                (params = {
                    ...params,
                    render: (_, data) => {
                        const checkGap = data.checkGap || 0;
                        const checkMd = data.checkMD || 0;
                        return (
                            <div>
                                <div>{checkGap}</div>
                                <div>{`(${checkMd})`}</div>
                            </div>
                        );
                    },
                });
            key === 'displayPriceLTPriceCount' &&
                (params = { ...params, width: 130 });
            key === 'cartPrice' &&
                (params = {
                    ...params,
                    render: (data) => (data ? getPrice(data) : null),
                });
            key === 'discount' &&
                (params = {
                    ...params,
                    render: (text) => Math.round(text) || '',
                });
            key === 'actionName' &&
                (params = {
                    ...params,
                    fixed: true,
                    render: (text, data) => {
                        if (text === 'Trade') {
                            return parseAttempt(data.actionParams);
                        }
                        return text ? (
                            <Tag color={ActionColor[text]}>{text}</Tag>
                        ) : null;
                    },
                });
            return params;
        });

    const dataSource: { [key: string]: any }[] = eventsData.map(
        (step, index) => {
            return {
                ...step,
                step: index + 1,
                key: index + 1,
                date: step.date?.split('.')?.[0],
                checkGap: Math.round(step.checkGap * 1000) / 1000,
                discount: Math.round(step.discount * 1000) / 1000,
                gain: Math.round(step.gain * 1000) / 1000,
                tradeDiscount: Math.round(step.tradeDiscount * 1000) / 1000,
                displayPrice: getFinalDisplayPrices(
                    step.productsMetrics,
                    cellsCount(eventsData)
                ),
            };
        }
    );

    const getProductsEventsData = (eventsData: ActionType[]) => {
        const allEvents = eventsData
            .filter((event) => event.actionName === 'AddToCart')
            .flatMap((event) => event.productsMetrics)
            .reverse();
        const uniqueEvents: ProductMetrics[] = [];
        allEvents.forEach((event) => {
            if (
                !uniqueEvents.find(
                    (uniqueEvent) =>
                        uniqueEvent.aggregationSign === event.aggregationSign
                )
            ) {
                uniqueEvents.push(event);
            }
        });
        return uniqueEvents;
    };

    dataSource.push({
        cartPrice: null,
        step: null,
        key: null,
        date: null,
        checkGap: null,
        discount: null,
        gain: null,
        tradeDiscount: null,
        displayPrice: getFinalStep(
            getProductsEventsData(eventsData),
            cellsCount(eventsData)
        ),
    });

    return (
        <Table
            className={'simple-table'}
            scroll={{ x: 1200 }}
            columns={columns(actionDesc, ['step', 'actionName'])}
            dataSource={dataSource}
            pagination={false}
        />
    );
};
