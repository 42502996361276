import React from 'react';
import styles from './styles.module.scss';
import { t } from 'i18next';
import MainButton from '../../../../shared-components/ui/Buttons/MainButton';
import type { CartProduct } from '../../../../../shared/graphql/client/generated/graphql.types';

type Props = {
    product: CartProduct;
    onDelete: () => void;
    onSave: () => void;
};

const RemoveItem = ({ onDelete, onSave }: Props) => {
    return (
        <div className={styles['remove-item__container']}>
            <p className={styles['remove-item__title']}>{t('21_SureRemove')}</p>
            <div className={styles['remove-item__buttons']}>
                <MainButton
                    text={t('22_SureRemoveAccept')}
                    small
                    stylesType="lightGray"
                    className={styles['remove-item__close_button']}
                    action={onDelete}
                />
                <MainButton
                    text={t('22_SureRemoveCancel')}
                    small
                    className={styles['remove-item__save_button']}
                    action={onSave}
                />
            </div>
        </div>
    );
};

export default RemoveItem;
