import React from 'react';

const QuestionMark = () => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 11C3.23857 11 1 8.7614 1 6C1 3.23857 3.23857 1 6 1C8.7614 1 11 3.23857 11 6C11 8.7614 8.7614 11 6 11ZM6.49955 8.5V7.5H5.49955V8.5H6.49955ZM6.49955 7V6.67755C7.2224 6.4624 7.74955 5.79275 7.74955 5C7.74955 4.0335 6.96605 3.25 5.99955 3.25C5.15055 3.25 4.44273 3.8546 4.2832 4.65673L5.26395 4.85288C5.33235 4.50911 5.6357 4.25 5.99955 4.25C6.41375 4.25 6.74955 4.58578 6.74955 5C6.74955 5.4142 6.41375 5.75 5.99955 5.75C5.7234 5.75 5.49955 5.97385 5.49955 6.25V7H6.49955Z"
                fill="#FF4B2B"
            />
        </svg>
    );
};

export default QuestionMark;
