import { useMutation } from '@apollo/client';
import { GET_CART, PRODUCT_ADD_BY_ID } from '../services/graphql';
import {
    CartProductAddByIdMutation,
    CartProductAddByIdMutationVariables,
} from '../../shared/graphql/client/generated/graphql.types';
import { handleAddProductError } from '../../shared/errors/handleAddProductError';

type Params = {
    onServerErrorCallback?: () => void;
    onCompleted?: () => void;
};

export const useMutationAddProductById = ({
    onCompleted,
    onServerErrorCallback,
}: Params = {}) =>
    useMutation<
        CartProductAddByIdMutation,
        CartProductAddByIdMutationVariables
    >(PRODUCT_ADD_BY_ID, {
        update: (cache, { data }) => {
            if (data) {
                cache.writeQuery({
                    query: GET_CART,
                    data: { cart: data.cartProductAddByID },
                });
            }
        },
        onCompleted,
        onError(error) {
            handleAddProductError(error, onServerErrorCallback);
        },
    });
