import { OfferState } from '../services/graphql';
import { countPrices } from '../utils';
import { GetCartQuery } from '../../shared/graphql/client/generated/graphql.types';

const calculateCartPrices = (cart: GetCartQuery['cart'] | undefined) => {
    const price = cart?.cartProducts?.reduce(
        (prev, cur) => prev + Number(cur.price) * Number(cur.count),
        0
    );

    const priceWithDiscount = cart?.cartProducts?.reduce(
        (prev, cur) =>
            prev +
            (cart?.offerState === OfferState.Condition && cur.inCondition
                ? Number(cur.price)
                : Number(cur.displayPrice)) *
                Number(cur.count),
        0
    );

    const nextPriceWithDiscount = cart?.cartProducts?.reduce((prev, cur) => {
        const prices = countPrices({
            product: cur,
            offerState: cart?.offerState as OfferState,
            current: cur.current,
        });
        return (
            prev + (prices.nextPrice || prices.displayPrice) * Number(cur.count)
        );
    }, 0);

    const priceCross = cart?.cartProducts?.reduce((acc, product) => {
        if (cart.offerState === OfferState.Agreed) {
            if (product.priceCross) {
                return acc + product.priceCross * Number(product.count);
            } else {
                return acc + Number(product.price) * Number(product.count);
            }
        } else if (
            cart.offerState === OfferState.Condition &&
            product.priceCross
        ) {
            return acc + product.priceCross * Number(product.count);
        }

        return acc;
    }, 0);

    return {
        price,
        priceWithDiscount,
        nextPriceWithDiscount,
        priceCross: priceCross ?? 0,
    };
};

export default calculateCartPrices;
