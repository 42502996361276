import React from 'react';
import PercentageAnimation from './PercentageAnimation';
import styles from './styles.module.scss';

const Percantage = () => {
    return (
        <div className={styles['loading-wrapper']}>
            <PercentageAnimation />
        </div>
    );
};

export default Percantage;
