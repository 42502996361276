import React, { useState } from 'react';
import dayjs from 'dayjs';
import { ReactComponent as TimeIcon } from '../../shared/images/icons/time.svg';
import clsx from 'clsx';
import s from './styles.module.scss';
import Button from '../../UiComponents/Button';
import { ReactComponent as UserIcon } from '../../shared/images/icons/user.svg';
import { ReactComponent as QrIcon } from '../../shared/images/icons/qr.svg';
import { ReactComponent as BellIcon } from '../../shared/images/icons/bell.svg';
import { ReactComponent as OptionsIcon } from '../../shared/images/icons/options.svg';
import { getNoun } from '../../shared/utils/utils';
import Settings from '../../UiComponents/Settings';
import CardSeller from '../../UiComponents/CardSeller';
import PriceString from '../../UiComponents/PriceString';
import {
    setClientSession,
    setShowScannerInCatalog,
} from '../../shared/utils/storage';
import { useHistory } from 'react-router-dom';
import FeedbackScreen from './FeedbackScreen';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../shared/constants';

interface Props {
    session: Session | any;
}

const SessionItem = ({ session }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const hours = dayjs(new Date()).diff(dayjs(session.createdAt), 'hour');
    const minutes = dayjs(new Date()).diff(dayjs(session.createdAt), 'm');

    const timeAgo =
        hours === 0
            ? `${minutes} ${getNoun(minutes, 'минуту', 'минуты', 'минут')}`
            : `${hours} ${getNoun(hours, 'час', 'часа', 'часов')}`;

    const [isOpenCartOptions, setOpenCartOptions] = useState(false);
    const [isOpenFeedback, setOpenFeedback] = useState(false);

    const amount = session.cart?.cartProducts?.reduce(
        (prev, product) => prev + product.displayPrice * product.count,
        0
    );
    const origAmount = session.cart?.cartProducts?.reduce(
        (prev, product) => prev + product.price * product.count,
        0
    );
    const discount = Math.ceil(100 * (1 - amount / origAmount));
    const bonusAmount = 0;

    return (
        <>
            <div className={s.SellerItem}>
                <div className={s.SellerItem__time}>
                    <TimeIcon />
                    <span style={{ marginLeft: 5 }}>{timeAgo} назад</span>
                </div>
                {session.cart?.cartProducts?.map((product) => (
                    <CardSeller
                        style={{ marginBottom: 16 }}
                        key={product.id}
                        type={'green'}
                        img={product.imageUrl}
                    >
                        <div className={s.SellerItem__content}>
                            <div>
                                {product.count} {t('шт.')}
                            </div>
                            {product.displayPrice !== product.price && (
                                <PriceString type="crossed">
                                    {product.price}
                                </PriceString>
                            )}
                            <PriceString type="green">
                                {product.displayPrice}
                            </PriceString>
                        </div>
                    </CardSeller>
                ))}
                {!!session.cart?.cartProducts?.length && (
                    <>
                        <div className={s.SellerItem__divider} />
                        <div className={s.SellerItem__total}>
                            <div className={s.SellerItem__total_amount}>
                                <PriceString type="green">{amount}</PriceString>
                                &nbsp;{!!discount && `(-${discount} %)`}
                            </div>
                            {!!bonusAmount && (
                                <Button type="bonus" text={100000} />
                            )}
                        </div>
                    </>
                )}
                <div className={s.SellerItem__menu}>
                    <Button
                        text={'Параметры корзины'}
                        icon={<OptionsIcon />}
                        type="black"
                        action={() => setOpenCartOptions(true)}
                        className={s.Seller__Btn}
                    />
                    <Button
                        text={'Открыть в клиентском режиме'}
                        type="black"
                        icon={<UserIcon />}
                        className={s.Seller__Btn}
                        action={async () => {
                            setShowScannerInCatalog(true);
                            await setClientSession(session.id);
                            history.push(
                                ROUTES.client.cartId.replace(
                                    ':cartId',
                                    session.cart.id
                                )
                            );
                        }}
                    />
                </div>

                {isOpenCartOptions && (
                    <Settings
                        title={'Параметры корзины'}
                        onClose={() => setOpenCartOptions(false)}
                    >
                        <Button
                            text={'QR-код'}
                            type="black"
                            icon={<QrIcon />}
                            action={async () => {
                                await setClientSession(session.id);
                                history.push(ROUTES.client.qrcode, {
                                    from: history.location.pathname,
                                });
                            }}
                            className={clsx(s.Seller__Btn, s.Seller__Btn_qr)}
                        />
                        <Button
                            text={'Сообщить о проблеме'}
                            type="black"
                            icon={<BellIcon />}
                            className={s.Seller__Btn}
                            action={() => setOpenFeedback(true)}
                        />
                    </Settings>
                )}

                {isOpenFeedback && (
                    <FeedbackScreen
                        cartId={session.cart.id}
                        onClose={() => setOpenFeedback(false)}
                    />
                )}
            </div>
        </>
    );
};

export default SessionItem;
