import React, { createContext, PropsWithChildren, useContext } from 'react';
import { AuthContextInterface } from './Auth.types';
import { useClientSessionRequest } from './hooks/useClientSessionRequest';
import common from '../styles/common.module.scss';
import PercentageLoader from '../../New/shared-components/ui/Loaders/PercentageLoader';

export const AuthContext = createContext<AuthContextInterface>(
    {} as AuthContextInterface
);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
    const { session, isSessionLoading, sessionError, loadClientSession } =
        useClientSessionRequest();

    if (isSessionLoading) {
        return (
            <div className={common.LoadingCover} style={{ display: 'flex' }}>
                <PercentageLoader />
            </div>
        );
    }

    return (
        <AuthContext.Provider
            // @ts-ignore
            value={{
                ...session,
                isSessionLoading,
                sessionError,
                loadClientSession,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
