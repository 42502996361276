import { i18n } from 'i18next';

export enum LanguagesEnum {
    'en' = 'en',
    'ru' = 'ru',
    'es' = 'es',
    'el' = 'el',
}

export type LanguagesType = keyof typeof LanguagesEnum;

export interface I18nContextInterface {
    language: LanguagesType;
    getLanguage: () => LanguagesType;
    getLanguages: () => LanguagesType[];
    setLanguage: (language: LanguagesType) => void;
    setLanguages: (languages: LanguagesType[]) => void;
    t: i18n['t'];
    i18n: i18n;
    isRuLanguage: boolean;
}
