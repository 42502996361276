import gql from 'graphql-tag';

// Seller
export const GET_SESSIONS = gql`
    query sessions {
        sessions {
            list {
                cart {
                    cartProducts {
                        color
                        current
                        packPrice
                        count
                        inCondition
                        displayPrice
                        priceCross
                        id
                        imageUrl
                        number
                        price
                        sizes
                        countTotal
                    }
                    id
                    level
                    attempts
                    offerState
                    goodOffer
                    betterOffer
                    tradeComplete
                    availableAttempts
                }
                createdAt
                id
            }
            pagination {
                limit
                page
                total
            }
        }
    }
`;

export const CREATE_SESSION = gql`
    mutation createSession {
        createSession {
            cart {
                cartProducts {
                    id
                }
                attempts
                id
                goodOffer
                betterOffer
            }
            createdAt
            id
        }
    }
`;

export const RESET_COOLDOWN = gql`
    mutation resetCooldown {
        resetCooldown
    }
`;

export const CREATE_CART_REPORT = gql`
    mutation createCartReport($type: String!, $text: String, $cartID: String!) {
        createCartReport(type: $type, text: $text, cartID: $cartID)
    }
`;

export const SELLER_METRICS = gql`
    {
        salesMetrics {
            salesmanMetrics {
                ad
                aov
                bonus
                bspqsm
                bssm
                phonesSm
                sales
                sinceDate
                upt
                username
            }
            shopMetrics {
                bspqs
                bspqsm
                bss
                fapsa
                fapsb
                phonesS
                phonesSm
                shopName
                sinceDate
                tsasa
                tsasb
            }
        }
    }
`;
