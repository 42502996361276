import { ApolloError } from '@apollo/client/errors';
import { t } from 'i18next';
import { NotificationManager } from 'react-notifications';
import * as Sentry from '@sentry/react';

export const handleAddProductError = (
    error: ApolloError,
    onServerErrorCallback?: () => void
) => {
    if (error.networkError?.name === 'ServerError') {
        onServerErrorCallback?.();
    } else if (
        [
            'product not in stock: cart error',
            'на складе недостаточно товара',
        ].includes(error.message)
    ) {
        NotificationManager.error(t('Остатков товара недостаточно'));
    } else if (error.message !== 'product already in cart') {
        NotificationManager.error(t('Не удалось добавить товар'));
        Sentry.captureException(error);
    }
};
