// SuperAdmin
import gql from 'graphql-tag';

export const ADMIN_GET_SHOPS = gql`
    query getShops {
        shops {
            id
            title
            type
        }
    }
`;

export const ADMIN_GET_CART_METRICS = gql`
    query cartOrderMetrics($shopID: String!, $begin: String, $end: String) {
        cartOrderMetrics(shopID: $shopID, begin: $begin, end: $end) {
            metricsSet {
                actionName
                cartID
                orderID
                cartPrice
                actionParams
                productsMetrics {
                    aggregationSign
                    bonus
                    count
                    displayPrice
                    existsInOrder
                    mD
                    price
                    threshold
                }
                cartSize
                checkGap
                date
                checkMD
                deltaTime
                discount
                displayPriceLTPriceCount
                gain
                juke
                productsInCartBeforeTrade
                totalBonus
                tradeDiscount
            }
        }
    }
`;

export const ADMIN_GET_SALES_METRICS = gql`
    query salesMetrics($shopID: String!) {
        salesMetrics(shopID: $shopID) {
            salesmanMetrics {
                ad
                aov
                bonus
                bspqsm
                bssm
                phonesSm
                sales
                sinceDate
                upt
                username
            }
            shopMetrics {
                bspqs
                bspqsm
                bss
                fapsa
                fapsb
                phonesS
                phonesSm
                sinceDate
                tsasa
                tsasb
            }
        }
    }
`;
