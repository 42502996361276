import React from 'react';
import cn from 'classnames';
import s from './styles.module.scss';

type Props = {
    loading?: boolean;
    stylesType?:
        | 'primary'
        | 'ghost'
        | 'green'
        | 'disabled'
        | 'black'
        | 'gray'
        | 'lightGray'
        | 'light'
        | 'bonus';
    type?: 'submit' | 'reset' | 'button' | undefined;
    text: string;
    link?: string;
    action?: () => void;
    className?: string;
    small?: boolean;
    icon?: JSX.Element;
    disabled?: boolean;
};

const MainButton = ({
    loading = false,
    stylesType = 'primary',
    text,
    link,
    action,
    small,
    type,
    className,
    icon,
    disabled,
}: Props) => {
    return (
        <button
            type={type}
            disabled={stylesType === 'disabled' || disabled}
            onClick={action}
            className={cn(s.Button, className, {
                [s.Button_ghost]: stylesType === 'ghost',
                [s.Button_green]: stylesType === 'green',
                [s.Button_disabled]: stylesType === 'disabled',
                [s.Button_disabled]: stylesType === 'gray',
                [s.Button_lightGray]: stylesType === 'lightGray',
                [s.Button_black]: stylesType === 'black',
                [s.Button_light]: stylesType === 'light',
                [s.Button_small]: small,
            })}
        >
            {icon ? <span className={s.Button__icon}>{icon} </span> : null}
            {loading ? <img src="" width={20} /> : <span>{text}</span>}
        </button>
    );
};

export default MainButton;
