import React, { useState } from 'react';
import s from './styles.module.scss';
import clsx from 'clsx';
import { FilterState } from './Catalog';
import { useTranslation } from 'react-i18next';
import { normalizeSizes } from '../../shared/utils/normalizeSizes';

interface Props {
    sizes: string[];
    filterState: FilterState;
    onFilterSelect: (size: string) => void;
    inCategory?: boolean;
}

const SizesFilter = ({
    sizes,
    filterState,
    onFilterSelect,
    inCategory,
}: Props) => {
    const { t } = useTranslation();
    const [showAll, toggleShowAll] = useState<boolean>(false);

    const sizesList = showAll ? sizes.sort() : sizes.sort().slice(0, 5);

    return (
        <div
            className={clsx(s.Filter, {
                [s.Filter__sizes]: inCategory,
            })}
        >
            {normalizeSizes(sizesList).map((size) => {
                const selected = filterState.size?.includes(size);
                return (
                    <div
                        key={size}
                        className={clsx(s.Filter__sizes_item, {
                            [s.Filter__sizes_item_selected]: selected,
                        })}
                        onClick={(e) => {
                            e.stopPropagation();
                            onFilterSelect(size);
                        }}
                    >
                        {size}
                    </div>
                );
            })}
            {sizes.length > 5 && (
                <div
                    className={clsx(s.Filter__more)}
                    onClick={() => {
                        toggleShowAll(!showAll);
                    }}
                >
                    {showAll ? t('Свернуть') : t('Еще размеры')}
                </div>
            )}
        </div>
    );
};

export default SizesFilter;
