import { useApolloClient } from '@apollo/client';
import { GET_CART } from '../services/graphql';
import { GetCartQuery } from '../../shared/graphql/client/generated/graphql.types';

export const useGetCart = () => {
    const client = useApolloClient();
    const data = client.readQuery<GetCartQuery>({
        query: GET_CART,
    });
    return data?.cart;
};
