import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import MainButton from '../../../../shared-components/ui/Buttons/MainButton';
import { t } from 'i18next';
import { useGetCart } from '../../../../hooks/useGetCart';
import { countDiscount, numberWithSpaces } from '../../../../utils';
import calculateCartPrices from '../../../../utils/calculateCartPrices';
import { getClientCurrency } from '../../../../../shared/utils/storage';
import { OfferState } from '../../../../services/graphql';
import { ROUTES } from '../../../../../shared/constants';

interface BuyContainerPropertiesInterface {
    isTradeCompleted: boolean;
    tradeControls: React.ReactNode;
}

const BuyContainer = ({
    isTradeCompleted,
    tradeControls,
}: BuyContainerPropertiesInterface) => {
    const history = useHistory();

    const cart = useGetCart();

    const cartPrices = calculateCartPrices(cart);

    const currency = getClientCurrency();

    return (
        <div className={styles['buy-container-wrapper']}>
            <div className={styles.Order}>
                <div className={styles.Order__top}>
                    <div>
                        {cart?.offerState === OfferState.Condition &&
                        cartPrices.priceWithDiscount !== cartPrices.price ? (
                            <div className={styles.OldPrice}>
                                {numberWithSpaces(cartPrices.price)} {currency}
                            </div>
                        ) : cartPrices.priceCross > 0 &&
                          cartPrices.priceWithDiscount !==
                              cartPrices.priceCross ? (
                            <div className={styles.OldPrice}>
                                {numberWithSpaces(cartPrices.priceCross)}{' '}
                                {currency}
                            </div>
                        ) : null}

                        <div className={styles.Price}>
                            {numberWithSpaces(cartPrices.priceWithDiscount)}{' '}
                            {currency}
                        </div>
                        <div className={styles.CurrentLvlInfo}>
                            <div className={styles.CurrentLvlInfo_flex}>
                                {isTradeCompleted
                                    ? t('12_CurrentLevelPrice')
                                    : t('12_CurrentLevel')}
                                &nbsp;
                                <div
                                    className={
                                        styles.CurrentLvlInfo_Bold_Container
                                    }
                                >
                                    {t('19_onLevel')} {Number(cart?.level)}
                                    <div
                                        className={styles.CurrentLvlInfo_Arrow}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {isTradeCompleted ? (
                        <MainButton
                            className={styles.BuyButton}
                            text={t('14_buy')}
                            stylesType="black"
                            small
                            action={() => history.push(ROUTES.client.qrcode)}
                        />
                    ) : (
                        tradeControls
                    )}
                </div>
                <div className={styles.Hint}>
                    <div
                        className={styles.text_container}
                        dangerouslySetInnerHTML={{
                            __html: t(
                                isTradeCompleted
                                    ? '13_addProductsForDiscount'
                                    : '13_addProductsForDiscount_TradeNotCompleted',
                                {
                                    level: isTradeCompleted
                                        ? cart?.targetLevel
                                        : Number(cart?.level) + 1,
                                    discount: numberWithSpaces(
                                        cart
                                            ? countDiscount({
                                                  products:
                                                      cart.cartProducts || [],
                                                  offerState:
                                                      cart.offerState as OfferState,
                                              }).toString()
                                            : '0'
                                    ),
                                    currency,
                                }
                            ),
                        }}
                    />

                    <MainButton
                        stylesType="lightGray"
                        small
                        text={t('10_addProducts')}
                        className={styles.Hint_AddProductsBtn}
                        action={() => history.push(ROUTES.client.catalog)}
                    />
                </div>
            </div>
        </div>
    );
};

export default BuyContainer;
