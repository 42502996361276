import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    removeAdminSession,
    setAdminSession,
} from '../../shared/utils/storage';
import axios from 'axios';
import s from './styles.module.scss';
import common from '../../shared/styles/common.module.scss';
import { Button, Form, Input, notification } from 'antd';
import PercentageLoader from '../../New/shared-components/ui/Loaders/PercentageLoader';
import { ROUTES } from '../../shared/constants';

const Auth = () => {
    const history = useHistory();
    const [loadingSession, setLoadingSession] = useState(false);

    const handleSubmit = (values) => {
        setLoadingSession(true);
        removeAdminSession();
        axios
            .post(`${process.env.REACT_APP_API_PATH}superadmin/login`, values)
            .then((resp) => {
                setAdminSession(resp.data.token);
                history.push(ROUTES.superAdmin.superAdmin);
            })
            .catch(() => {
                notification.error({ message: 'Неверный логин или пароль' });
            })
            .finally(() => {
                setLoadingSession(false);
            });
    };

    return (
        <div className={s.SuperadminAuth__container}>
            <div
                className={common.LoadingCover}
                style={{ display: loadingSession ? 'flex' : 'none' }}
            >
                <PercentageLoader />
            </div>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Логин"
                    name="username"
                    rules={[
                        { required: true, message: 'Пожалуйста введите логин' },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите пароль',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 17, span: 16 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={s.SuperadminAuth__btn}
                    >
                        Войти
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Auth;
