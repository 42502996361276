import { useLazyQuery, useQuery } from '@apollo/client';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CloseIconGray } from '../../shared/images/icons/close-gray.svg';
import { ReactComponent as CloseIconBlack } from '../../shared/images/icons/close.svg';
import { ReactComponent as QrCodeIcon } from '../../shared/images/icons/qr-code.svg';
import BaseModal from '../../UiComponents/BaseModal';
import { Dropdown } from '../../UiComponents/Dropdown';
import { SearchInput } from '../../UiComponents/SearchInput';
import {
    getShowScannerInCatalog,
    saveFilterStateToLocalStorage,
} from '../../shared/utils/storage';
import { ReactComponent as FilterIcon } from '../../shared/images/icons/filter.svg';
import { AsconaFilter } from './Catalog';
import s from './styles.module.scss';
import { graphqlClientFront, LinkType } from '../../shared/graphql/clients';
import { ROUTES } from '../../shared/constants';
import {
    GET_FILTER_NUMBERS,
    GET_NEW_CATALOG_FILTERS,
    ON_MODEL_SELECT,
    SEARCH_MODEL,
} from '../../shared/graphql/client/graphql';

interface Props {
    filterState: Record<keyof AsconaFilter, string>;
    setFilterState: (state: Record<keyof AsconaFilter, string>) => void;
    categories: string[];
    categoriesLoading: boolean;
}

//  TODO need to clear each dropdown field if prev value in linked list is empty and set current (which is empty) to any
export const FilterMonoCatalog = ({
    filterState,
    setFilterState,
    categories,
    categoriesLoading,
}: Props) => {
    const { t } = useTranslation();
    const shopId = localStorage.getItem('shop_id');
    const [isOpened, setIsOpened] = React.useState(true);
    const [searchVal, setSearchVal] = React.useState<string>('');

    const history = useHistory();
    const setFilterStateWithStorage = (
        filterState: Record<keyof AsconaFilter, string>
    ) => {
        setFilterState(filterState);
        saveFilterStateToLocalStorage(shopId as string, filterState);
    };
    const [getData, { loading: searchLoading, data }] = useLazyQuery(
        SEARCH_MODEL,
        {
            context: { linkType: LinkType.Client },
            client: graphqlClientFront,
        }
    );

    const [
        getDataOnNumberClick,
        { loading: currentNumberClickLoading, data: currentNumberData },
    ] = useLazyQuery(ON_MODEL_SELECT, {
        context: { linkType: LinkType.Client },
        client: graphqlClientFront,
    });

    const anyOptionMap = {
        category: t('Любая категория'),
        number: t('Любая модель'),
        size: t('Любой размер'),
        options: t('Любые опции'),
    };

    // For numbers, wait until categories are selected and categoriesData is not loading
    const skipNumbersQuery = !filterState.category || categoriesLoading;
    const { data: numberFilters, loading: numbersLoading } = useQuery(
        GET_FILTER_NUMBERS,
        {
            context: { linkType: LinkType.Client },
            client: graphqlClientFront,
            variables: { category: filterState.category },
            skip: skipNumbersQuery,
        }
    );

    // For sizes, wait until numbers are selected and numberFiltersData is not loading
    const skipSizesQuery =
        !filterState.category || !filterState.number || numbersLoading;
    const { data: sizeFilters, loading: sizesLoading } = useQuery(
        GET_NEW_CATALOG_FILTERS,
        {
            context: { linkType: LinkType.Client },
            client: graphqlClientFront,
            variables: {
                category: filterState.category,
                number: filterState.number,
            },
            skip: skipSizesQuery,
        }
    );

    // Similarly for options, wait until sizes are selected and sizeFiltersData is not loading
    const skipOptionsQuery =
        !filterState.category ||
        !filterState.number ||
        !filterState.size ||
        sizesLoading;
    const { data: options } = useQuery(GET_NEW_CATALOG_FILTERS, {
        context: { linkType: LinkType.Client },
        client: graphqlClientFront,
        variables: {
            category: filterState.category,
            number: filterState.number,
            size: filterState.size,
        },
        skip: skipOptionsQuery,
    });

    // TODO this invokes rerender and cancel request appolo need to fix it somehow
    useEffect(() => {
        // this invokes whe se select a number from search drowpdown
        if (!currentNumberClickLoading && !!currentNumberData) {
            // client.cache.reset();
            setFilterStateWithStorage({
                category: currentNumberData.aggregatedProducts.categories[0],
                number: currentNumberData.aggregatedProducts.numbers[0],
                size: '',
                options: '',
            });
        }
    }, [currentNumberClickLoading, currentNumberData]);

    useEffect(() => {
        searchVal &&
            !searchLoading &&
            getData({ variables: { search: searchVal, limit: 50, page: 1 } });
    }, [searchVal]);

    const arraysDataByKeys = {
        category: categories || [],
        number: numberFilters?.aggregatedProducts?.numbers || [],
        size: sizeFilters?.aggregatedProducts?.sizes || [],
        options: options?.aggregatedProducts?.options || [],
    };

    const getFilterDropdown = (filterKey: keyof AsconaFilter) => {
        return (
            <Dropdown
                selected={filterState[filterKey] || ''}
                onEmptySelect={() => {}}
                setSelected={(selected) => {
                    setFilterStateWithStorage({
                        ...filterState,
                        [filterKey]: selected,
                    });
                    if (filterKey === 'number') {
                        setFilterStateWithStorage({
                            ...filterState,
                            number: selected,
                            size: '',
                            options: '',
                        });
                    }
                    if (filterKey === 'size') {
                        setFilterStateWithStorage({
                            ...filterState,
                            size: selected,
                            options: '',
                        });
                    }
                    if (filterKey === 'category') {
                        setFilterStateWithStorage({
                            category: selected,
                            number: '',
                            size: '',
                            options: '',
                        });
                    }
                }}
                items={[
                    {
                        value: '',
                        label: anyOptionMap[filterKey],
                    },
                    ...arraysDataByKeys[filterKey].map((category) => {
                        return { value: category, label: category };
                    }),
                ]}
            />
        );
    };

    const filterStateButton = (option: keyof AsconaFilter) => (
        <div className={s.FilterStateButton}>
            {filterState[option]}
            <button
                className={s.FilterCloseButton}
                onClick={() => {
                    if (option === 'category') {
                        setFilterStateWithStorage({
                            category: '',
                            number: '',
                            size: '',
                            options: '',
                        });
                    } else {
                        setFilterStateWithStorage({
                            ...filterState,
                            [option]: '',
                        });
                    }
                }}
            >
                <CloseIconBlack />
            </button>
        </div>
    );

    return (
        <>
            <div style={{ marginBottom: '8px' }}>
                <SearchInput<string>
                    loading={searchLoading}
                    onSelect={async (number) => {
                        getDataOnNumberClick({
                            variables: { number, limit: 1, page: 1 },
                        });
                    }}
                    onDebounceFinish={async (searchVal: string) => {
                        searchVal && setSearchVal(searchVal);
                    }}
                    items={
                        data?.aggregatedProducts?.numbers?.map((val) => ({
                            value: val,
                            label: val,
                        })) || []
                    }
                />
                {getShowScannerInCatalog() && (
                    <button
                        className={s.qr}
                        onClick={() => history.push(ROUTES.client.scanCode)}
                    >
                        <QrCodeIcon />
                    </button>
                )}
            </div>

            <div>
                <button
                    className={cn(s.FilterButton, {
                        [s.FilterButtonActive]: Object.values(filterState).some(
                            (value) => value !== ''
                        ),
                    })}
                    onClick={() => setIsOpened(true)}
                >
                    <FilterIcon />
                    {t('Фильтры')}
                </button>
                <div className={s.FilterStateButtonsBlock}>
                    {filterState.category
                        ? filterStateButton('category')
                        : null}
                    {filterState.number ? filterStateButton('number') : null}
                    {filterState.size ? filterStateButton('size') : null}
                    {filterState.options ? filterStateButton('options') : null}
                </div>
            </div>
            <BaseModal
                isOpen={isOpened}
                onClose={() => setIsOpened(false)}
                maxHeight={'auto'}
                width={312}
                okText={t('Применить')}
            >
                <div className={s.ModalHeader}>
                    <div className={s.FilterModalTitle}>{t('Фильтры')}</div>
                    <button
                        onClick={() => setIsOpened(false)}
                        className={s.CloseButton}
                    >
                        <CloseIconGray />
                    </button>
                </div>
                <div>
                    <div className={s.FilterItem}>
                        {getFilterDropdown('category')}
                    </div>
                    {filterState.category ? (
                        // TODO add by array of filters
                        <>
                            <div className={s.FilterItem}>
                                {numberFilters?.aggregatedProducts?.numbers
                                    ?.length
                                    ? getFilterDropdown('number')
                                    : null}
                            </div>
                            <div className={s.FilterItem}>
                                {sizeFilters?.aggregatedProducts?.sizes?.length
                                    ? getFilterDropdown('size')
                                    : null}
                            </div>
                            <div className={s.FilterItem}>
                                {options?.aggregatedProducts?.options?.length
                                    ? getFilterDropdown('options')
                                    : null}
                            </div>
                        </>
                    ) : null}
                </div>
            </BaseModal>
        </>
    );
};
