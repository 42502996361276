import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { t } from 'i18next';

type Props = {
    level: number;
};
type LvlDirection = '' | 'up' | 'down';

const UpdatedLvlInfo = ({ level }: Props) => {
    const [prevLevel, setPrevLevel] = useState(level);
    const [lvlDirection, setLvlDirection] = useState<LvlDirection>('');

    useEffect(() => {
        let prevLevelVar = prevLevel;

        if (
            window.onAddProductTransaction &&
            window.onAddProductTransaction.from === 'catalog'
        ) {
            prevLevelVar = window.onAddProductTransaction.level;
            window.onAddProductTransaction = undefined;
        }
        if (prevLevelVar < level) {
            setLvlDirection('up');
        } else if (prevLevelVar > level) {
            setLvlDirection('down');
        } else {
            setLvlDirection('');
        }
        setPrevLevel(level);
    }, [level]);

    const styleClasses = cn(
        styles.update_lvl_info,
        lvlDirection === 'up' && styles.update_lvl_info__increase,
        lvlDirection === 'down' && styles.update_lvl_info__decrease
    );
    return lvlDirection !== '' ? (
        <div
            className={styleClasses}
            onAnimationEnd={({ animationName }) => {
                if (animationName === styles.fadeOutDown) {
                    setLvlDirection('');
                }
            }}
        >
            {lvlDirection === 'up' && t('23_LevelIncreased')}
            {lvlDirection === 'down' && t('24_LevelDecreased')}
        </div>
    ) : null;
};

export default UpdatedLvlInfo;
