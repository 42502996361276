import React, { useEffect, useState } from 'react';
import s from './styles.module.scss';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import GridDynamic from './DynamicGrid';
import PercentageLoader from '../../New/shared-components/ui/Loaders/PercentageLoader';
import { graphqlClientFront, LinkType } from '../../shared/graphql/clients';
import { GET_CATALOG_BY_CATEGORY } from '../../shared/graphql/client/graphql';

interface Props {
    title: string;
    level: number;
    size: string[];
    sizeLoading: boolean;
    female: boolean;
    male: boolean;
    cartId: string;
    onOpen: () => void;
    withFooter?: boolean;
}

export type FilterState = {
    male: boolean;
    female: boolean;
    size: string[];
};

const filterProducts = (
    data: any[],
    male: boolean,
    female: boolean,
    size: string[]
) =>
    data.filter((product) => {
        if ((male && product.sex === 'M') || (female && product.sex === 'W')) {
            if (size.length && product.sizes?.length) {
                if (
                    !size.filter((size) => product.sizes?.includes(size)).length
                ) {
                    return false;
                }
            }
            return true;
        }
        return false;
    });

const CategoryContent = ({
    title,
    level,
    size,
    sizeLoading,
    male,
    female,
    cartId,
    onOpen,
    withFooter,
}: Props) => {
    const [retryCount, setRetryCount] = useState(0);
    const [infiniteLength, setInfiniteLength] = useState(0);
    const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
    const parentRef = React.useRef<HTMLDivElement>(null);

    const {
        loading: dataLoading,
        data,
        error,
        refetch,
    } = useQuery(GET_CATALOG_BY_CATEGORY, {
        context: { linkType: LinkType.Client },
        client: graphqlClientFront,
        variables: {
            page: 1,
            limit: 1000,
            category: title,
        },
        errorPolicy: 'all',
        onCompleted: () => {
            onOpen();
            setRetryCount(0);
        },
    });

    useEffect(() => {
        if (error && retryCount < 3) {
            const timer = setTimeout(() => {
                refetch();
                setRetryCount(retryCount + 1);
            }, 1000 * retryCount);

            return () => clearTimeout(timer);
        }
        if (retryCount >= 3 && error) {
            console.error('Max retries reached with error:', error.message);
        }
    }, [error, retryCount, refetch]);

    useEffect(() => {
        if (data) {
            const filtered = filterProducts(
                data.aggregatedProducts.list,
                male,
                female,
                size
            );
            setFilteredProducts(filtered);
            setInfiniteLength(filtered.length);
        }
    }, [male, female, size, data]);

    const isLoading = dataLoading || sizeLoading;

    return (
        <div
            className={clsx(
                s.MonoCategory,
                s['catalog__catalog-items-container'],
                withFooter && s.MonoCategory_withFooter
            )}
            ref={parentRef}
            id="scrollable"
        >
            {isLoading ? (
                <PercentageLoader />
            ) : (
                <GridDynamic
                    ref={parentRef}
                    items={filteredProducts}
                    total={infiniteLength}
                    cartLevel={level}
                    cartId={cartId}
                />
            )}
        </div>
    );
};

export default CategoryContent;
