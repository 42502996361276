import { useMutation } from '@apollo/client';
import {
    CartProductAddByAggregationSignResponse,
    PRODUCT_ADD_BY_AGGREGATION_SIGN,
} from '../services/graphql';
import { GET_CART } from '../../../services/graphql';
import { handleAddProductError } from '../../../../shared/errors/handleAddProductError';

type Params = {
    onServerErrorCallback?: () => void;
    onCompleted?: () => void;
};

export const useMutationAddProductByAggregationSign = ({
    onCompleted,
    onServerErrorCallback,
}: Params = {}) =>
    useMutation<CartProductAddByAggregationSignResponse>(
        PRODUCT_ADD_BY_AGGREGATION_SIGN,
        {
            update: (cache, { data }) => {
                if (data) {
                    cache.writeQuery({
                        query: GET_CART,
                        data: { cart: data.cartProductAddByAggregationSign },
                    });
                }
            },
            onCompleted,
            onError(error) {
                window.onAddProductTransaction = undefined;

                handleAddProductError(error, onServerErrorCallback);
            },
        }
    );
