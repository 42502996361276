import { AuthClientSessionResponseInterface } from '../Auth.types';
import { asyncLocalStorage } from '../../utils/storage';

const CLIENT_SESSION_LOCAL_STORAGE_KEY = 'client_session';

const setSession = async (
    session: Partial<AuthClientSessionResponseInterface>
) => {
    return asyncLocalStorage.setItem(
        CLIENT_SESSION_LOCAL_STORAGE_KEY,
        JSON.stringify(session)
    );
};

const getSession = (): AuthClientSessionResponseInterface | null => {
    const session = localStorage.getItem(CLIENT_SESSION_LOCAL_STORAGE_KEY);
    if (session) {
        return JSON.parse(session);
    } else {
        return null;
    }
};

const removeSession = () => {
    localStorage.removeItem(CLIENT_SESSION_LOCAL_STORAGE_KEY);
};

export const clientSession = {
    set: setSession,
    get: getSession,
    remove: removeSession,
};
