// @ts-nocheck
import React, { useRef, useEffect } from 'react';

import { LoadMoreTrigger, useVirtualizer, useGrid } from '..';
import { useScrollMargin } from '../useScrollMargin';

export interface GridProps {
    grid: ReturnType<typeof useGrid>;
    children: (index: number) => React.ReactNode;
    items: any[];
}

// domain logic shouldnt be here
const estimateRowHeight = (rowIndex, colCount, items) => {
    try {
        const firtItemInARow = items[rowIndex * colCount];
        const lastItemInARow = items[rowIndex * colCount + colCount - 1];

        const countSizesSymbols = (item) =>
            item.sizes.reduce((acc, size) => acc + size.length, 0);

        return countSizesSymbols(firtItemInARow) >= 12 ||
            countSizesSymbols(lastItemInARow) >= 12
            ? 351
            : 330;
    } catch (e) {
        return 351;
    }
};

export const Grid = ({ grid, children, items }: GridProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const { scrollMargin } = useScrollMargin({
        scrollRef: grid.scrollRef,
        gridRef: ref,
    });

    const rowVirtualizer = useVirtualizer({
        ...grid.rowVirtualizer,
        scrollMargin: scrollMargin.top,
        paddingEnd: 220,
        estimateSize: (i) => estimateRowHeight(i, grid.columnCount, items),
    });

    const columnVirtualizer = useVirtualizer({
        ...grid.columnVirtualizer,
        scrollMargin: scrollMargin.left,
    });

    useEffect(() => {
        rowVirtualizer.measure();
    }, [rowVirtualizer, grid.virtualItemHeight]);

    useEffect(() => {
        columnVirtualizer.measure();
    }, [columnVirtualizer, grid.virtualItemWidth]);

    return (
        <div
            ref={ref}
            style={{
                position: 'relative',
                width: `${columnVirtualizer.getTotalSize()}px`,
                height: `${rowVirtualizer.getTotalSize()}px`,
            }}
        >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                <React.Fragment key={virtualRow.key}>
                    {columnVirtualizer
                        .getVirtualItems()
                        .map((virtualColumn) => {
                            const virtualItem = grid.getVirtualItem({
                                row: virtualRow,
                                column: virtualColumn,
                                scrollMargin: scrollMargin,
                            });

                            if (!virtualItem) return null;

                            return (
                                <div
                                    key={virtualColumn.key}
                                    style={virtualItem.style}
                                >
                                    {children(virtualItem.index)}
                                </div>
                            );
                        })}
                </React.Fragment>
            ))}

            <LoadMoreTrigger
                {...grid.getLoadMoreTrigger({
                    virtualizer: grid.options.horizontal
                        ? columnVirtualizer
                        : rowVirtualizer,
                })}
            />
        </div>
    );
};
