import React, { useEffect, useState } from 'react';
import s from '../styles.module.scss';
import common from '../../../shared/styles/common.module.scss';
import {
    Button,
    Col,
    DatePicker,
    Form,
    notification,
    Row,
    Select,
    SelectProps,
    Table,
} from 'antd';
import { logout } from '../../../shared/utils/token';
import { api } from '../../../api';
import { Link } from 'react-router-dom';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { ARMANI_ID } from '../../../New/utils/constants';
import { StocksCollections } from '../StocksCollections';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const SalesSummary = () => {
    const [items, setItems] = useState<any[]>([]);
    const [stocksCollectionsItems, setStocksCollectionsItems] = useState<any[]>(
        []
    );
    const [isLoading, toggleLoading] = useState<boolean>(false);
    const [
        isStocksCollectionsItemsLoading,
        toggleStocksCollectionsItemsLoading,
    ] = useState<boolean>(false);
    const [options, setOptions] = useState<SelectProps['options']>([]);

    const shopId = localStorage.getItem('shop_id');

    const getItems = async (filter?) => {
        try {
            toggleLoading(true);
            const { data } = await api.shop_admin_report_sales_summary(filter);

            setItems(data);
        } catch (e) {
            notification.error({
                // @ts-ignore
                message: e.data?.error?.message || 'Неверные данные',
            });
        } finally {
            toggleLoading(false);
        }
    };

    const getStocksCollectionsItems = async (filter?) => {
        try {
            toggleStocksCollectionsItemsLoading(true);
            const { data } =
                await api.shop_admin_report_collections_stocks_percentage(
                    filter
                );

            setStocksCollectionsItems(data);
        } catch (e) {
            notification.error({
                // @ts-ignore
                message: e.data?.error?.message || 'Неверные данные',
            });
        } finally {
            toggleStocksCollectionsItemsLoading(false);
        }
    };

    const getOptions = async () => {
        const options: SelectProps['options'] = [];
        const { data } = await api.shop_admin_report_get_seasons();
        for (const season of data) {
            options.push({
                label: season,
                value: season,
            });
        }
        setOptions(options);
    };

    const [form] = Form.useForm();
    useEffect(() => {
        getOptions();

        form.submit();
    }, []);

    const submit = (values: any) => {
        const filter = {
            dateFrom: values.dates[0].format('YYYY-MM-DDTHH:mm:ssZ'),
            dateTo: values.dates[1].format('YYYY-MM-DDTHH:mm:ssZ'),
            seasons: values.seasons.join(','),
        };
        getItems(filter);

        if (shopId === ARMANI_ID) {
            getStocksCollectionsItems(filter);
        }
    };

    const columns = [
        {
            title: 'Категория',
            dataIndex: 'order_kind',
        },
        {
            title: 'Выручка',
            dataIndex: 'total_earnings',
        },
        {
            title: 'Полная цена',
            dataIndex: 'total_products_price',
        },
        {
            title: 'Скидка, руб',
            dataIndex: 'total_discount',
        },
        {
            title: 'Скидка, %',
            dataIndex: 'average_discount_percent',
            render: (value) => (parseFloat(value) * 100).toFixed(0) + '%',
        },
        {
            title: 'Количество чеков',
            dataIndex: 'checks_count',
        },
        {
            title: 'Реализовано единиц',
            dataIndex: 'quantity_sold',
        },
        {
            title: 'Среднее кол-во ед в чеке',
            dataIndex: 'average_check_quantity',
        },
        {
            title: 'Средний чек',
            dataIndex: 'average_check_price',
        },
    ];

    return (
        <div className={common.AdminPage}>
            <div className={s.TableControls}>
                <div className={s.PageControls}>
                    <Link to={`/axaxax36ax/items-admin`} className={s.Link}>
                        {'Назад'}
                    </Link>
                    <Button className={s.Button} onClick={logout}>
                        Выход
                    </Button>
                </div>
            </div>
            <div style={{ maxWidth: 1100, margin: 'auto' }}>
                <div>
                    <Form
                        form={form}
                        initialValues={{
                            dates: [
                                dayjs().startOf('month').subtract(1, 'month'),
                                dayjs().startOf('month'),
                            ],
                            seasons: [],
                        }}
                        onFinish={submit}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Коллекции" name="seasons">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Выбор коллекций"
                                        options={options}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label="Даты"
                                    name="dates"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input dates!',
                                        },
                                    ]}
                                >
                                    <RangePicker
                                        locale={locale}
                                        allowEmpty={[false, false]}
                                        disabledDate={(current) => {
                                            // Can not select dates before Sept 2022
                                            return (
                                                current &&
                                                current <
                                                    dayjs(
                                                        '01-09-2022',
                                                        'DD-MM-YYYY'
                                                    )
                                            );
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        name="submit"
                                    >
                                        Фильтровать
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div>
                    <div style={{ padding: 20 }}>
                        <span>{'Сводный отчёт'}</span>
                    </div>
                    <Table
                        pagination={false}
                        rowKey={'order_kind'}
                        loading={isLoading}
                        columns={columns}
                        dataSource={items}
                    />
                </div>
                {shopId === ARMANI_ID ? (
                    <div>
                        <div style={{ padding: '40px 20px 20px 20px' }}>
                            <span>{'Остатки по коллекциям'}</span>
                        </div>
                        <StocksCollections
                            items={stocksCollectionsItems}
                            isLoading={isStocksCollectionsItemsLoading}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default SalesSummary;
