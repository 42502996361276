import React from 'react';
import { Modal, Table } from 'antd';
import { on } from 'cluster';

interface Props {
    visible: boolean;
    onCancel: () => void;
    data:
        | {
              aggregated_product: ProductType;
              products: ProductType[];
          }
        | undefined;
}

const ProductData = ({ data, visible, onCancel }: Props) => {
    const keys = data
        ? [
              'tableTitle',
              ...Object.keys(data.products[0] || data.aggregated_product),
          ]
        : [];
    const columns = keys.map((key) => ({
        title: key,
        dataIndex: key,
        render: (value) => String(value),
        fixed: key === 'tableTitle' ? ('left' as const) : false,
    }));

    const formattedProducts = data
        ? [data.aggregated_product, ...data.products].map((product, index) => {
              if (index === 0) {
                  return {
                      tableTitle: 'Отображаемый продукт',
                      ...product,
                  };
              } else {
                  return {
                      tableTitle: `Продукт ${index}`,
                      ...product,
                  };
              }
          })
        : [];

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            onOk={onCancel}
            width={'auto'}
        >
            <div>
                <Table
                    columns={columns}
                    scroll={{ x: 3000 }}
                    dataSource={formattedProducts}
                />
            </div>
        </Modal>
    );
};

export default ProductData;
