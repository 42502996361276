import React, { ReactElement } from 'react';
import s from './accordion.module.scss';
import { ReactComponent as AccordionArrowIcon } from './../../shared/images/icons/accordion-arrow.svg';
import clsx from 'clsx';
import SizesFilter from '../../Components/Catalog/SizesFilter';
import { FilterState } from '../../Components/Catalog/Catalog';

interface Props {
    title: string;
    children: ReactElement | null;
    isOpen: boolean;
    setIsOpen: (category: string | undefined) => void;
    withTopFilter: boolean;
    filter?: {
        sizes: string[];
        state: FilterState;
        onSelect: (size: string) => void;
    };
    withFooter?: boolean;
}

const Accordion = ({
    title,
    children,
    isOpen,
    setIsOpen,
    withTopFilter = true,
    filter,
    withFooter,
}: Props) => {
    const showFilter =
        filter &&
        !!filter.sizes.length &&
        !(filter.sizes.length === 1 && filter.sizes[0] === 'TU');
    return (
        <div
            className={clsx(s.Accordion, {
                [s.Accordion_withFooter]: withFooter,
            })}
        >
            <div
                className={clsx(
                    s.AccordionTitleContainer,
                    !withTopFilter && s.AccordionTitleContainer_withoutFilter
                )}
            >
                <div
                    data-category={title}
                    className={clsx(s.AccordionTitle, {
                        [s.AccordionTitle_discount]: [
                            'до -50%',
                            'до -40%',
                        ].includes(title),
                    })}
                    onClick={
                        isOpen
                            ? () => setIsOpen(undefined)
                            : () => setIsOpen(title)
                    }
                >
                    <div>
                        {title[0].toUpperCase()}
                        {title.slice(1)}
                    </div>
                    <AccordionArrowIcon />
                </div>
                {showFilter && (
                    <SizesFilter
                        inCategory
                        sizes={filter.sizes}
                        filterState={filter.state}
                        onFilterSelect={filter.onSelect}
                    />
                )}
            </div>
            <div className={s.accordionContent} aria-expanded={!isOpen}>
                {children}
            </div>
        </div>
    );
};

export default Accordion;
