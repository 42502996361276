import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import Category from './Category';
import { FilterState } from './Catalog';
import { useTranslation } from 'react-i18next';
import { getCategoryStateFromLocalStorage } from '../../shared/utils/storage';
import type { GetCartQuery } from '../../shared/graphql/client/generated/graphql.types';

interface Props {
    cart?: GetCartQuery['cart'];
    filterState: FilterState;
    sizesInCategories: boolean;
    withoutFilter: boolean;
    categories: string[];
    setFilterState: Dispatch<SetStateAction<FilterState>>;
    onOpen: (categoryName: string | undefined) => void;
    onClose: () => void;
    openedCategory: string | undefined;
    setOpenedCategory: Dispatch<SetStateAction<string | undefined>>;
    withFooter?: boolean;
}

export const ListCategories: FC<Props> = ({
    onOpen,
    onClose,
    setFilterState,
    categories,
    cart,
    filterState,
    sizesInCategories,
    withoutFilter,
    openedCategory,
    setOpenedCategory,
    withFooter,
}) => {
    const { t } = useTranslation();
    const shopId = localStorage.getItem('shop_id');
    const state = getCategoryStateFromLocalStorage(shopId);

    const categoryName = (category: string) => {
        switch (category) {
            case 'discountCategory40':
                return t('до -40%');
            case 'discountCategory50':
                return t('до -50%');
            default:
                return category;
        }
    };

    useEffect(() => {
        if (!openedCategory) {
            onClose();
        }
    }, [openedCategory]);

    useEffect(() => {
        if (state) {
            setFilterState({
                male: state.male,
                female: state.female,
                size: state.size,
            });
        }
    }, []);

    if (openedCategory) {
        return (
            <Category
                withFooter={withFooter}
                onOpen={() => onOpen(openedCategory)}
                sizeArr={state?.size ?? []}
                cartId={cart?.id ?? ''}
                level={cart?.level ?? 0}
                withTopFilter={withoutFilter ? false : sizesInCategories}
                title={openedCategory}
                filterState={filterState}
                isOpened
                onSetOpenedCategory={() => setOpenedCategory(undefined)}
            />
        );
    }

    return (
        <>
            {categories.map((category) => (
                <Category
                    onOpen={() => onOpen(openedCategory)}
                    sizeArr={state?.category === category ? state?.size : []}
                    cartId={cart?.id ?? ''}
                    level={cart?.level ?? 0}
                    withTopFilter={withoutFilter ? false : sizesInCategories}
                    key={category}
                    title={categoryName(category) || 'unknown'}
                    filterState={filterState}
                    isOpened={
                        (categoryName(category) || 'unknown') === openedCategory
                    }
                    onSetOpenedCategory={() => {
                        setOpenedCategory((prev) =>
                            prev === (categoryName(category) || 'unknown')
                                ? undefined
                                : categoryName(category) || 'unknown'
                        );
                    }}
                />
            ))}
        </>
    );
};
