import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module.scss';

interface TooltipProps {
    children: React.ReactNode;
    content: React.ReactNode;
    visibleOnInit: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
    children,
    content,
    visibleOnInit,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            tooltipRef.current &&
            !tooltipRef.current.contains(event.target as Node)
        ) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        setIsVisible(visibleOnInit);
    }, [visibleOnInit]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={tooltipRef} style={{ position: 'relative' }}>
            <div
                onClick={() => setIsVisible(!isVisible)}
                style={{ cursor: 'pointer' }}
            >
                {children}
            </div>
            {isVisible && <div className={styles.tooltip}>{content}</div>}
        </div>
    );
};

export default Tooltip;
