import React, { useEffect, useRef, useState } from 'react';
import s from '../styles.module.scss';
import common from '../../../shared/styles/common.module.scss';
import { Button, Input, notification, Table } from 'antd';
import { logout } from '../../../shared/utils/token';
import { api } from '../../../api';
import { Link } from 'react-router-dom';

/*const stubs = [
    {
        "aggregation_sign": "a2198a0e8f5c9cd9b18a0927c023b819",
        "collection": "Осень-Зима 2023",
        "category": "Ботинки",
        "number": "XUM011XV614",
        "color": "Черный+Черный (BLACK+BLACK)",
        "max_discount": 20,
        "real_threshold": 27.44,
        "sort_dif": 1
    },
    {
        "aggregation_sign": "0c017613e7a57028f8034b133a19c5a7",
        "collection": "Осень-Зима 2023",
        "category": "Брюки",
        "number": "6RZPHEZJDGZ",
        "color": "Белый (OFF WHITE)",
        "max_discount": 20,
        "real_threshold": 24.71,
        "sort_dif": 0.3
    },
    {
        "aggregation_sign": "7830e540001c610801b22e7bd6df60fc",
        "collection": "Осень-Зима 2023",
        "category": "Брюки",
        "number": "6RYP10YN2KZ",
        "color": "Черный (BLACK)",
        "max_discount": 20,
        "real_threshold": 21.86,
        "sort_dif": 0.1
    }
];*/

const ProductRatings = () => {
    const [items, setItems] = useState<any[]>([]);
    const [isLoading, toggleLoading] = useState<boolean>(false);

    const getItems = async () => {
        try {
            toggleLoading(true);
            const { data } = await api.shop_admin_report_product_ratings();

            setItems(data);
        } catch (e) {
            notification.error({
                // @ts-ignore
                message: e.data?.error?.message || 'Неверные данные',
            });
        } finally {
            toggleLoading(false);
        }
    };

    useEffect(() => {
        getItems();
    }, []);

    const filterItems = (columnName) => {
        // @ts-ignore
        const nonRepeatItems = [
            // @ts-ignore
            ...new Set(items.map((item) => item[columnName])),
        ];
        return nonRepeatItems.map((value) => ({
            text: value,
            value: value,
        }));
    };

    const searchInput = useRef<any>();
    const getColumnSearchProps = (columnName) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder="Search"
                    allowClear
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                        setTimeout(() => confirm(false), 500);
                    }}
                    onPressEnter={() => confirm()}
                />
            </div>
        ),
        onFilter: (value, record) =>
            record[columnName]
                ? record[columnName]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                //@ts-ignore
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
    });

    const columns = [
        {
            title: 'Артикул',
            dataIndex: 'number',
            ...getColumnSearchProps('number'),
            width: 200,
        },
        {
            title: 'Цвет',
            dataIndex: 'color',
            ...getColumnSearchProps('color'),
        },
        {
            title: 'Коллекция',
            dataIndex: 'collection',
            filterSearch: true,
            filters: filterItems('collection'),
            onFilter: (value, record) => record.collection === value,
        },
        {
            title: 'Категория',
            dataIndex: 'category',
            filterSearch: true,
            filters: filterItems('category'),
            onFilter: (value, record) => record.category === value,
        },
        {
            title: 'Текущая макс. скидка, %',
            dataIndex: 'max_discount',
            sorter: (a, b) => a.max_discount - b.max_discount,
        },
        {
            title: 'Рекомендуемая скидка, %',
            dataIndex: 'real_threshold',
            sorter: (a, b) => a.real_threshold - b.real_threshold,
        },
        {
            title: 'Sort dif',
            dataIndex: 'sort_dif',
            sorter: (a, b) => a.sort_dif - b.sort_dif,
        },
    ];

    return (
        <div className={common.AdminPage}>
            <div className={s.TableControls}>
                <div className={s.PageControls}>
                    <Link to={`/axaxax36ax/items-admin`} className={s.Link}>
                        {'Назад'}
                    </Link>
                    <Button className={s.Button} onClick={logout}>
                        Выход
                    </Button>
                </div>
            </div>
            <div style={{ margin: 'auto', padding: '0 10px' }}>
                <div style={{ padding: 20 }}>
                    <span>{'Рейтинг товаров'}</span>
                </div>
                <Table
                    pagination={false}
                    rowKey={'aggregation_sign'}
                    loading={isLoading}
                    columns={columns}
                    dataSource={items}
                />
            </div>
        </div>
    );
};

export default ProductRatings;
