import React from 'react';
import clsx from 'clsx';
import s from './styles.module.scss';
import { numberWithSpaces } from '../../../../utils';
import { t } from 'i18next';
import { getClientCurrency } from '../../../../../shared/utils/storage';

interface Props {
    old?: number;
    actual?: number;
    className?: string;
}

const Price = ({ old, actual, className }: Props) => {
    const currency = getClientCurrency();
    return (
        <div className={clsx(s.Price, className)}>
            <div className={clsx(s.Price__numbers)}>
                {!!old && (
                    <div className={clsx(s.Price__value, s.Price__value_old)}>
                        <span>
                            {numberWithSpaces(old)} {currency}
                        </span>
                    </div>
                )}
                <div
                    className={clsx(s.Price__value, {
                        [s.Price__value_actual]: actual,
                    })}
                >
                    <span>
                        {!!actual && `${numberWithSpaces(actual)} ${currency}`}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Price;
