import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import common from '../../../shared/styles/common.module.scss';
import React, { useState } from 'react';
import s from '../styles.module.scss';
import { Table, Tabs } from 'antd';
import PercentageLoader from '../../../New/shared-components/ui/Loaders/PercentageLoader';
import {
    graphqlClientSuperAdmin,
    LinkType,
} from '../../../shared/graphql/clients';
import { ADMIN_GET_SALES_METRICS } from '../../../shared/graphql/superadmin/graphql';

const ResultsPage = () => {
    const { shopId } = useParams<{ shopId: string }>();
    const options = {
        context: { linkType: LinkType.SuperAdmin },
        variables: { shopID: shopId },
        client: graphqlClientSuperAdmin,
    };

    const { loading, data } = useQuery(ADMIN_GET_SALES_METRICS, options);

    const [activeTab, setTab] = useState('1');

    if (loading) {
        return (
            <div
                className={common.LoadingCover}
                style={{ display: loading ? 'flex' : 'none' }}
            >
                <PercentageLoader />
            </div>
        );
    }

    const desc = {
        bspqs: 'Доля чеков магазина через систему && prescan_quantity > 1',
        bspqsm: 'Доля чеков продаца через систему && prescan_quantity > 1',
        bss: 'Доля чеков магазина через систему',
        fapsa: '% предложенной цены на первой попытке у брошеных корзин',
        fapsb: '% предложенной цены на первой попытке у купленых корзин',
        phonesS: 'Число собранных номеров телефонов по магазину',
        phonesSm: 'Число собранных номеров телефонов по продацу',
        tsasa: '% торгов со второй попыткой у брошеных корзин',
        tsasb: '% торгов со второй попыткой у купленых корзин',
        ad: 'Средний размер скидки продавца',
        aov: 'Средний чек продавца',
        bonus: 'Сумма бонусов продавца',
        bssm: 'Доля чеков продаца через систему',
        sales: 'Сумма продаж продавца',
        sinceDate: 'Дата начала периода',
        upt: 'Среднее число товаров в чеке продавца',
    };

    const columns = [
        {
            title: 'Параметр',
            dataIndex: 'name',
            key: 'name',
            render: (text) => (
                <>
                    <div>{text}</div>
                    <div className={s.Shop__content_desc}>{desc[text]}</div>
                </>
            ),
        },
        {
            title: `За день ${
                (activeTab === '1' &&
                    data?.shopMetrics?.[0].sinceDate?.split(' ')?.[0]) ||
                ''
            }`,
            dataIndex: 'day',
            key: 'day',
        },
        {
            title: `За месяц ${
                (activeTab === '1' &&
                    data?.shopMetrics?.[1].sinceDate?.split(' ')?.[0]) ||
                ''
            }`,
            dataIndex: 'month',
            key: 'month',
        },
    ];

    const sourceDataShop: any = [];
    const metricsShop = data?.salesMetrics?.shopMetrics;
    metricsShop?.[0] &&
        Object.keys(metricsShop?.[0]).forEach((key) => {
            !['sinceDate', '__typename'].includes(key) &&
                sourceDataShop.push({
                    name: key,
                    key,
                    day: metricsShop?.[0][key] || 0,
                    month: metricsShop?.[1][key] || 0,
                });
        });

    const metricsSellers = data?.salesMetrics?.salesmanMetrics;
    const usernames = Array.from(
        new Set<string>(metricsSellers?.map((i) => i.username))
    )
        .sort((a, b) => a.localeCompare(b))
        .map((username, i) => ({ username, key: i }));

    const renderSellerMetrics = ({ username }) => {
        const sourceDataSellers: any = [];
        metricsSellers?.[0] &&
            Object.keys(metricsSellers?.[0]).forEach((key) => {
                const metricsSeller = metricsSellers.filter(
                    (i) => i.username === username
                );
                if (key === 'sinceDate') {
                    sourceDataSellers.push({
                        name: key,
                        key,
                        day: metricsSeller?.[0][key]?.split(' ')[0] || 0,
                        month: metricsSeller?.[1][key]?.split(' ')[0] || 0,
                    });
                } else if (!['username', '__typename'].includes(key)) {
                    sourceDataSellers.push({
                        name: key,
                        key,
                        day: metricsSeller?.[0][key] || 0,
                        month: metricsSeller?.[1][key] || 0,
                    });
                }
            });

        return (
            <Table
                columns={columns}
                dataSource={sourceDataSellers}
                pagination={false}
            />
        );
    };

    const { TabPane } = Tabs;

    return (
        <Tabs
            defaultActiveKey={'1'}
            activeKey={activeTab}
            onChange={(key: string) => setTab(key)}
        >
            <TabPane tab="Магазин" key="1">
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={sourceDataShop}
                ></Table>
            </TabPane>
            <TabPane tab="Продавцы" key="2">
                <Table
                    showHeader={false}
                    pagination={false}
                    columns={[
                        {
                            title: `Имя`,
                            dataIndex: 'username',
                            key: 'username',
                        },
                    ]}
                    expandable={{
                        expandedRowRender: renderSellerMetrics,
                        defaultExpandedRowKeys: ['0'],
                    }}
                    dataSource={usernames}
                />
            </TabPane>
        </Tabs>
    );
};

export default ResultsPage;
