import { gql } from '@apollo/client';

export enum OfferState {
    Condition = 'condition',
    Agreed = 'acceptance',
}

export const GET_CART = gql`
    query getCart {
        cart {
            id
            cartProducts {
                color
                current
                packPrice
                count
                inCondition
                displayPrice
                id
                imageUrl
                number
                price
                priceCross
                sizes
                countTotal
                priceCross
            }
            level
            targetLevel
            attempts
            offerState
            goodOffer
            betterOffer
            tradeComplete
            availableAttempts
        }
    }
`;

export const PRODUCT_ADD_BY_ID = gql`
    mutation cartProductAddByID($productID: String!) {
        cartProductAddByID(productID: $productID) {
            cartProducts {
                color
                current
                packPrice
                count
                inCondition
                displayPrice
                id
                imageUrl
                number
                price
                sizes
                countTotal
                priceCross
            }
            id
            level
            attempts
            tradeComplete
            availableAttempts
            targetLevel
            offerState
            goodOffer
            betterOffer
        }
    }
`;

export const PRODUCT_MINUS = gql`
    mutation cartProductMinus($cartProductID: String!) {
        cartProductMinus(cartProductID: $cartProductID) {
            cartProducts {
                color
                current
                packPrice
                count
                inCondition
                displayPrice
                id
                imageUrl
                number
                price
                sizes
                countTotal
                priceCross
            }
            id
            level
            attempts
            offerState
            goodOffer
            betterOffer
            tradeComplete
            availableAttempts
            targetLevel
        }
    }
`;

export const PRODUCT_PLUS = gql`
    mutation cartProductPlus($cartProductID: String!) {
        cartProductPlus(cartProductID: $cartProductID) {
            cartProducts {
                color
                current
                packPrice
                count
                inCondition
                displayPrice
                id
                imageUrl
                number
                price
                sizes
                countTotal
                priceCross
            }
            id
            level
            attempts
            offerState
            goodOffer
            betterOffer
            tradeComplete
            availableAttempts
            targetLevel
        }
    }
`;

export const PRODUCT_REMOVE = gql`
    mutation cartProductRemove($cartProductID: String!) {
        cartProductRemove(cartProductID: $cartProductID) {
            cartProducts {
                color
                current
                packPrice
                count
                inCondition
                displayPrice
                id
                imageUrl
                number
                price
                sizes
                countTotal
                priceCross
            }
            id
            level
            attempts
            offerState
            goodOffer
            betterOffer
            tradeComplete
            availableAttempts
            targetLevel
        }
    }
`;
