import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import common from '../../shared/styles/common.module.scss';
import s from './styles.module.scss';
import Button from '../../UiComponents/Button';
import { ReactComponent as ShopIcon } from '../../shared/images/icons/shopping-bag.svg';
import SessionItem from './SessionItem';
import { ReactComponent as UnlockIcon } from '../../shared/images/icons/unlock.svg';
import { ReactComponent as LogoutIcon } from '../../shared/images/icons/logout.svg';
import { ReactComponent as SettingsIcon } from '../../shared/images/icons/settings.svg';
import { ReactComponent as ResultsIcon } from '../../shared/images/icons/results.svg';
import { useMutation, useQuery } from '@apollo/client';
import {
    CREATE_SESSION,
    GET_SESSIONS,
    RESET_COOLDOWN,
    SELLER_METRICS,
} from '../../graphql';
import {
    AsconaId,
    AsconaId2Ok,
    getAsconaSession,
    getClientCurrency,
    getEmptySession,
    removeAsconaSession,
    removeClientSession,
    removeEmptySession,
    removeSellerSession,
    setAsconaSession,
    setClientSession,
    setEmptySession,
    setShowScannerInCatalog,
} from '../../shared/utils/storage';
import Settings from '../../UiComponents/Settings';
import { useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import numberWithSpaces from '../../shared/utils/utils';
import { clearData } from '../../shared/redux/appSlice';
import { useAppDispatch } from '../../shared/redux/store';
import PercentageLoader from '../../New/shared-components/ui/Loaders/PercentageLoader';
import { graphqlClientSeller, LinkType } from '../../shared/graphql/clients';
import { ROUTES } from '../../shared/constants';

const Seller = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [isOpenSettings, setOpenSettings] = useState(false);
    const [isOpenResults, setOpenResults] = useState(false);
    const [isShowTodayResults, setShowTodayResults] = useState(true);
    const [sellerMetrics, setSellerMetrics]: [any, any] = useState({});

    const options = {
        context: { linkType: LinkType.Seller },
        client: graphqlClientSeller,
    };

    const { loading, data, error, refetch } = useQuery(GET_SESSIONS, options);
    const { loading: loadingMetrics, data: dataMetrics } = useQuery(
        SELLER_METRICS,
        options
    );
    const [mutateOnResetCooldown] = useMutation(RESET_COOLDOWN, options);
    const [mutateOnCreateSession] = useMutation(CREATE_SESSION, options);
    const [refetching, setRefetching] = useState(false);

    if (error) {
        removeSellerSession();
        removeClientSession();
        removeAsconaSession();
        history.push(ROUTES.seller.sellerAuth);
    }

    const sessions = data?.sessions?.list || [];

    useEffect(() => {
        if (dataMetrics) {
            isShowTodayResults
                ? setSellerMetrics(
                      dataMetrics?.salesMetrics?.salesmanMetrics?.[0] || {}
                  )
                : setSellerMetrics(
                      dataMetrics?.salesMetrics?.salesmanMetrics?.[1] || {}
                  );
        }
        if (dataMetrics?.salesMetrics?.shopMetrics?.[0]?.shopName == 'Аскона') {
            setAsconaSession(AsconaId);
        }
        if (
            dataMetrics?.salesMetrics?.shopMetrics?.[0]?.shopName == 'Аскона 2'
        ) {
            setAsconaSession(AsconaId2Ok);
        }
    }, [dataMetrics, isShowTodayResults]);

    useEffect(() => {
        let timeoutId: any;
        const repeat = async () => {
            updateData();
            if (timeoutId) {
                timeoutId = setTimeout(repeat, 60000);
            }
        };
        timeoutId = setTimeout(repeat, 0);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    const onResetCooldown = () => {
        setRefetching(true);
        mutateOnResetCooldown()
            .catch((e) =>
                NotificationManager.error(
                    t('Не удалось выполнить сброс заморозки'),
                    undefined,
                    undefined,
                    () => alert(e)
                )
            )
            .finally(() => setRefetching(false));
    };

    const onCreateSession = async () => {
        dispatch(clearData());
        const alreadyExists =
            !!getEmptySession() &&
            !!sessions.find(
                (s) =>
                    s.id === getEmptySession() &&
                    !s.cart?.cartProducts?.length &&
                    !s.cart?.attempts?.length
            );
        if (alreadyExists) {
            setShowScannerInCatalog(true);
            await setClientSession(getEmptySession() || '').then(() => {
                history.push(ROUTES.client.catalog);
            });
        } else {
            removeEmptySession();
            setRefetching(true);
            mutateOnCreateSession()
                .then(async (data) => {
                    setEmptySession(data.data.createSession.id);
                    setShowScannerInCatalog(true);
                    await setClientSession(data.data.createSession.id).then(
                        () => {
                            if (getAsconaSession()) {
                                window.location.pathname =
                                    ROUTES.client.catalog;
                            } else {
                                history.push(ROUTES.client.catalog);
                            }
                        }
                    );
                })
                .catch((e) =>
                    NotificationManager.error(
                        t('Не удалось создать новую корзину'),
                        undefined,
                        undefined,
                        () => alert(e)
                    )
                )
                .finally(() => setRefetching(false));
        }
    };

    const updateData = () => {
        refetch().catch(() => {
            removeSellerSession();
            removeClientSession();
            removeAsconaSession();
            history.push(ROUTES.seller.sellerAuth);
        });
    };

    if (loading) {
        return (
            <div
                className={common.LoadingCover}
                style={{ display: loading || refetching ? 'flex' : 'none' }}
            >
                <PercentageLoader />
            </div>
        );
    }

    return (
        <>
            <div>
                <div
                    className={common.LoadingCover}
                    style={{ display: loading || refetching ? 'flex' : 'none' }}
                >
                    <PercentageLoader />
                </div>
                <div className={s.Header}>
                    <Button
                        className={s.Seller__Btn}
                        type="black"
                        icon={<SettingsIcon />}
                        text={t('Настройка')}
                        action={() => setOpenSettings(true)}
                    />
                    <Button
                        className={s.Seller__Btn}
                        type="black"
                        icon={<ShopIcon />}
                        text={t('Новая корзина')}
                        action={onCreateSession}
                    />
                </div>
                {sessions?.map((session) => (
                    <SessionItem key={session.id} session={session} />
                ))}
            </div>

            {isOpenSettings && !isOpenResults && (
                <Settings
                    title={t('Настройка')}
                    onClose={() => setOpenSettings(false)}
                >
                    <Button
                        className={s.Seller__Btn}
                        type="black"
                        icon={<UnlockIcon />}
                        text={t('Сброс заморозки')}
                        action={onResetCooldown}
                    />
                    <Button
                        className={s.Seller__Btn}
                        type="black"
                        icon={<ResultsIcon />}
                        text={t('Результаты')}
                        action={() => setOpenResults(true)}
                    />
                    <Button
                        className={s.Seller__Btn}
                        type="black"
                        icon={<LogoutIcon />}
                        text={t('Выход')}
                        action={() => {
                            removeSellerSession();
                            removeClientSession();
                            removeAsconaSession();
                            history.push(ROUTES.seller.sellerAuth);
                        }}
                    />
                </Settings>
            )}
            {isOpenResults && (
                <Settings
                    title={t('Результаты')}
                    withBackBtn={false}
                    onClose={() => {
                        setOpenResults(false);
                        setShowTodayResults(true);
                    }}
                >
                    <div
                        className={common.LoadingCover}
                        style={{ display: loadingMetrics ? 'flex' : 'none' }}
                    >
                        <PercentageLoader />
                    </div>
                    {!loadingMetrics && (
                        <div className={s.Seller__Results}>
                            <div>
                                <Button
                                    text={t('Сегодня')}
                                    type={
                                        isShowTodayResults ? 'black' : 'ghost'
                                    }
                                    action={() => setShowTodayResults(true)}
                                />
                                <Button
                                    text={t('За месяц')}
                                    type={
                                        isShowTodayResults ? 'ghost' : 'black'
                                    }
                                    action={() => setShowTodayResults(false)}
                                />
                            </div>
                            <div className={s.Seller__Results__divider} />
                            <p>{`${numberWithSpaces(
                                sellerMetrics.sales
                            )} ${getClientCurrency()}`}</p>
                            <p>
                                {t('Средний чек: {{aov}} RUB', {
                                    aov: sellerMetrics.aov,
                                })}
                            </p>
                            <p>
                                {t('Товаров в чеке: {{upt}}', {
                                    upt: sellerMetrics.upt,
                                })}
                            </p>
                            <p>
                                {t('Чеков с BATNA: {{bssm}} %', {
                                    bssm: sellerMetrics.bssm * 100,
                                })}
                            </p>
                            <Button text={sellerMetrics.bonus} type="bonus" />
                        </div>
                    )}
                </Settings>
            )}
        </>
    );
};

export default Seller;
