import { useMutation } from '@apollo/client';
import { TRADE } from '../services/graphql';
import { GET_CART } from '../../../services/graphql';
import * as Sentry from '@sentry/react';
import { useAuth } from '../../../../shared/auth/Auth.context';

type Params = {
    onCompleted?: () => void;
};

export const useMutationSendTradeOffer = ({ onCompleted }: Params = {}) => {
    const { session_id } = useAuth();

    return useMutation(TRADE, {
        update: (cache, { data: { trade } }) => {
            cache.writeQuery({ query: GET_CART, data: { cart: trade } });
        },
        onError: (error) => {
            Sentry.withScope(function (scope) {
                scope.setExtra('error_message', 'Error sending trade offer');
                scope.setExtra('session_id', session_id);

                Sentry.captureException(error);
            });

            console.error('Error sending trade offer:', error);
        },
        onCompleted,
    });
};
