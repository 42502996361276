import React from 'react';
import styles from './styles.module.scss';
import { t } from 'i18next';
import MainButton from '../../../../shared-components/ui/Buttons/MainButton';
import Price from './Price';
import { useMutationAddProductByAggregationSign } from '../../hooks/useMutationAddProductByAggregationSign';
import { useHistory } from 'react-router-dom';
import getRussianPluralKey from '../../../../utils/getRussianPluralKey';
import { useGetCart } from '../../../../hooks/useGetCart';
import CatalogSizes from './CatalogSizes';
import {
    getMonoCatalog,
    getShowPriceCross,
    setCatalogReload,
} from '../../../../../shared/utils/storage';
import {
    graphqlClientFront,
    LinkType,
} from '../../../../../shared/graphql/clients';
import { useI18n } from '../../../../../shared/i18n';
import { ROUTES } from '../../../../../shared/constants';
import type { AggregatedProduct } from '../../../../../shared/graphql/client/generated/graphql.types';

interface Props {
    product: AggregatedProduct;
    level: number;
    style?: React.CSSProperties;
    cartId: string;
}

const CatalogItem = ({ product, level, style, cartId }: Props) => {
    const { isRuLanguage } = useI18n();
    const history = useHistory();
    const plusLevel = product.getLevel - level;
    const isMonoCatalog = getMonoCatalog();

    const [mutationAddProductByAggregationSign] =
        useMutationAddProductByAggregationSign({
            onCompleted: () => {
                history.push(ROUTES.client.cartId.replace(':cartId', cartId));
                setCatalogReload(true);
                localStorage.setItem('needUpdateCatalogue', 'true');
            },
        });
    const plusLevelTranslation = isRuLanguage
        ? `25_plusLevel${getRussianPluralKey(plusLevel)}`
        : '25_plusLevel';

    const cart = useGetCart();

    const shouldUseCrossedPrice = getShowPriceCross();

    const price = shouldUseCrossedPrice ? product.priceCross : product.price;

    return (
        <div className={styles.CatalogItem} style={style}>
            <div style={{ position: 'relative', height: 200 }}>
                {cart?.tradeComplete && plusLevel !== 0 && (
                    <div className={styles.CatalogItem__points}>
                        {t(plusLevelTranslation, {
                            count: plusLevel,
                        })}
                    </div>
                )}
                <img
                    loading="lazy"
                    className={styles.CatalogItem__img}
                    src={product.imageUrl}
                    alt="dominant color placeholder"
                />
            </div>
            <div
                className={
                    isMonoCatalog
                        ? styles.CatalogItem__monoContentBlock
                        : styles.CatalogItem__contentBlock
                }
            >
                <div
                    className={
                        isMonoCatalog
                            ? styles.CatalogItem__monoTitleBlock
                            : styles.CatalogItem__titleBlock
                    }
                >
                    <p
                        className={
                            isMonoCatalog
                                ? styles.CatalogItem__descMono
                                : styles.CatalogItem__desc
                        }
                    >
                        {isMonoCatalog ? product.color : product.number}
                    </p>
                    {!isMonoCatalog && product.sizes && (
                        <CatalogSizes sizes={product.sizes} />
                    )}
                </div>

                <div style={{ paddingBottom: '10px' }}>
                    <Price
                        className={styles.CatalogItem__price}
                        old={price === product.displayPrice ? undefined : price}
                        actual={product.displayPrice}
                    />
                    <MainButton
                        action={() => {
                            window.onAddProductTransaction = {
                                from: 'catalog',
                                level: level,
                            };
                            mutationAddProductByAggregationSign({
                                variables: {
                                    aggregationSign: product.aggregationSign,
                                },
                                context: { linkType: LinkType.Client },
                                client: graphqlClientFront,
                            });
                        }}
                        className={styles.CatalogItem__btn}
                        text={t('15_AddToBasket')}
                        stylesType="ghost"
                        small
                    />
                </div>
            </div>
        </div>
    );
};

export default CatalogItem;
