import React from 'react';
import styles from './styles.module.scss';
import PercentageIcon from '../../../../assets/PercentageIcon';

const PercentageAnimation: React.FC = () => {
    return (
        <div className={styles.loaderPercentageIcon}>
            <PercentageIcon />
        </div>
    );
};

export default PercentageAnimation;
