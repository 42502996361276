import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import appReducer from './appSlice';
import {
    AggregatedProduct,
    CartProduct,
} from '../graphql/client/generated/graphql.types';

export type ReduxState = {
    pointsRequired: number;
    pointsTargetProduct: CartProduct;
    pointsProducts: AggregatedProduct[];
    isLoading: boolean;
    isShowHint: boolean;
    cartOfferState: string;

    theme: {
        main_color: string;
        dark_100_color: string;
        dark_50_color: string;
        positive_color: string;
        negative_color: string;
    };
};

const store = configureStore({
    reducer: {
        app: appReducer,
    },
    devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<{
    app: ReduxState;
}> = useSelector;

export default store;
