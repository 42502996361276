import React from 'react';
import s from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
    const { t } = useTranslation();
    return (
        <div className={s.ErrorPage}>
            {t('Страница не найдена')}
            <div className={s.ErrorPage__error}>404</div>
        </div>
    );
};

export default ErrorPage;
