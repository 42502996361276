import React from 'react';

const Warning = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.23584 7.50836V11.675C9.23584 12.1334 9.61084 12.5084 10.0692 12.5084C10.5275 12.5084 10.9025 12.1334 10.9025 11.675V7.50836C10.9025 7.05002 10.5275 6.67502 10.0692 6.67502C9.61084 6.67502 9.23584 7.05002 9.23584 7.50836Z"
                fill="#FF4B2B"
            />
            <path
                d="M9.91921 13.3583C9.46921 13.4417 9.16083 13.875 9.24416 14.325C9.3275 14.775 9.76086 15.0833 10.2109 15C10.6609 14.9167 10.9692 14.4833 10.8859 14.0333C10.8026 13.5833 10.3692 13.275 9.91921 13.3583Z"
                fill="#FF4B2B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5023 2.49169L18.3939 15.0334L18.4023 15.0417C18.7023 15.5417 18.7023 16.1667 18.4023 16.6917C18.1023 17.2167 17.5606 17.5334 16.9523 17.5334H3.20228C2.59394 17.5334 2.05229 17.2167 1.75229 16.6917C1.45229 16.1667 1.45229 15.5417 1.76063 15.0167L8.63563 2.51669V2.49169C8.93563 1.97502 9.47727 1.66669 10.0689 1.66669C10.6606 1.66669 11.2023 1.97502 11.5023 2.49169ZM10.0689 3.33335L3.20228 15.8334L16.9439 15.8584L10.0689 3.33335Z"
                fill="#FF4B2B"
            />
        </svg>
    );
};

export default Warning;
