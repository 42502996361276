import React, { ReactNode } from 'react';
import clsx from 'clsx';
import s from './styles.module.scss';
import { Link } from 'react-router-dom';
import preloader from './../../shared/images/preloader.gif';
import { ReactComponent as BonusIcon } from '../../shared/images/icons/bonus.svg';
import numberWithSpaces from '../../shared/utils/utils';

interface Props {
    loading?: boolean;
    type?:
        | 'primary'
        | 'ghost'
        | 'green'
        | 'disabled'
        | 'black'
        | 'gray'
        | 'lightGray'
        | 'light'
        | 'bonus';
    text: string | ReactNode | number;
    link?: string;
    action?: () => void;
    className?: string;
    small?: boolean;
    icon?: JSX.Element;
    disabled?: boolean;
}

const Button = ({
    loading,
    type = 'primary',
    text,
    link,
    action,
    small,
    className,
    icon,
    disabled,
}: Props) => {
    if (type === 'bonus') {
        return (
            <button
                onClick={() => {}}
                className={clsx(
                    s.Button,
                    s.Button_green,
                    s.Button_bonus,
                    className
                )}
            >
                <span className={s.Button__icon}>{<BonusIcon />}</span>
                <span>
                    {typeof text === 'number' ? numberWithSpaces(text) : text}
                </span>
            </button>
        );
    }
    if (action) {
        return (
            <button
                disabled={type === 'disabled' || disabled}
                onClick={!loading ? () => action() : () => {}}
                className={clsx(s.Button, className, {
                    [s.Button_ghost]: type === 'ghost',
                    [s.Button_green]: type === 'green',
                    [s.Button_disabled]: type === 'disabled',
                    [s.Button_disabled]: type === 'gray',
                    [s.Button_lightGray]: type === 'lightGray',
                    [s.Button_black]: type === 'black',
                    [s.Button_light]: type === 'light',
                    [s.Button_small]: small,
                })}
            >
                {icon ? <span className={s.Button__icon}>{icon} </span> : null}
                {loading ? (
                    <img src={preloader} width={20} />
                ) : (
                    <span>{text}</span>
                )}
            </button>
        );
    }
    return (
        <Link
            /* @ts-ignore */
            to={!loading && (type === 'disabled' || disabled) ? '#' : link}
            className={clsx(s.Button, className, {
                [s.Button_ghost]: type === 'ghost',
                [s.Button_green]: type === 'green',
                [s.Button_disabled]: type === 'disabled',
                [s.Button_disabled]: type === 'gray',
                [s.Button_black]: type === 'black',
                [s.Button_light]: type === 'light',
                [s.Button_small]: small,
            })}
        >
            {icon ? <span className={s.Button__icon}>{icon} </span> : null}
            {loading ? <img src={preloader} width={20} /> : <span>{text}</span>}
        </Link>
    );
};

export default Button;
