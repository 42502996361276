import React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as ChevronDown } from '../../shared/images/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../shared/images/icons/chevron-up.svg';
import s from './styles.module.scss';

interface Props {
    selected?: string;
    setSelected: (selected: string) => void;
    items: {
        value: string;
        label: string;
    }[];
    onEmptySelect?: () => void;
}

export const Dropdown: FC<Props> = ({ selected, setSelected, items }) => {
    const [isActive, setIsActive] = useState(false);

    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            // @ts-ignore
            if (ref.current && !ref.current.contains(event.target)) {
                setIsActive(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const selectedLabel =
        items.find((item) => item.value === selected)?.label ?? '';

    return (
        <div className={s.dropdown} ref={ref}>
            <div
                onClick={() => {
                    setIsActive(!isActive);
                }}
                className={s.dropdownBtn}
            >
                <div className={s.selected}>{selectedLabel}</div>
                {isActive ? <ChevronUp /> : <ChevronDown />}
            </div>
            <div
                className={s.dropdownContent}
                style={{ display: isActive ? 'block' : 'none' }}
            >
                {items.map(({ value, label }) => (
                    <div
                        key={value}
                        onClick={(e) => {
                            setSelected(value);
                            setIsActive(!isActive);
                        }}
                        className={s.item}
                    >
                        {label}
                    </div>
                ))}
            </div>
        </div>
    );
};
