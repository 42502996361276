import React from 'react';
import TradeDialog from './TradeDialog';
import { GetCartQuery } from '../../../../../shared/graphql/client/generated/graphql.types';

type Props = {
    cart: GetCartQuery['cart'];
};

const Trade = ({ cart }: Props) => {
    const products = cart.cartProducts || [];

    return <TradeDialog product={products[products.length - 1]} />;
};

export default Trade;
