import { t } from 'i18next';
import React from 'react';
import MainButton from '../../../../shared-components/ui/Buttons/MainButton';
import styles from './styles.module.scss';
import { countDiscount, numberWithSpaces } from '../../../../utils';
import { useHistory } from 'react-router-dom';
import { getClientCurrency } from '../../../../../shared/utils/storage';
import { ROUTES } from '../../../../../shared/constants';
import type { GetCartQuery } from '../../../../../shared/graphql/client/generated/graphql.types';

interface CatalogBuyContainerPropertiesInterface {
    cart?: GetCartQuery['cart'];
}

const CatalogBuyContainer = ({
    cart,
}: CatalogBuyContainerPropertiesInterface) => {
    const history = useHistory();

    const currency = getClientCurrency();

    return (
        <div className={styles.CatalogInfoContainer}>
            <div className={styles.CatalogInfoContainer__top_wrapper}>
                <div className={styles.CatalogInfoContainer__lvl_info}>
                    {t('16_YouAreAt')}&nbsp;
                    <div
                        className={
                            styles['CatalogInfoContainer__lvl_info-current']
                        }
                    >
                        {t('16_YouAreAtLevel', {
                            level: cart?.level,
                        })}
                        <div
                            className={
                                styles[
                                    'CatalogInfoContainer__lvl_info-current__arrow'
                                ]
                            }
                        />
                    </div>
                </div>
                <MainButton
                    className={styles.CatalogInfoContainer__back_button}
                    small
                    stylesType="lightGray"
                    text={t('18_BackToBasket')}
                    action={() => {
                        if (cart?.id) {
                            history.push(
                                ROUTES.client.cartId.replace(':cartId', cart.id)
                            );
                        }
                    }}
                />
            </div>
            <div className={styles.CatalogInfoContainer__Hint}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: t(
                            cart?.tradeComplete
                                ? '13_addProductsForDiscount'
                                : '13_addProductsForDiscount_TradeNotCompleted',
                            {
                                level: cart?.tradeComplete
                                    ? cart?.targetLevel
                                    : Number(cart?.level) + 1,
                                discount: numberWithSpaces(
                                    cart
                                        ? countDiscount({
                                              // @ts-ignore -- TODO: add right types
                                              products: cart.cartProducts,
                                              // @ts-ignore -- TODO: add right types
                                              offerState: cart.offerState,
                                          }).toString()
                                        : '0'
                                ),
                                currency,
                            }
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default CatalogBuyContainer;
