import React from 'react';
import MainButton from '../../../../shared-components/ui/Buttons/MainButton';

import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import { countDiscount, numberWithSpaces } from '../../../../utils';
import Tooltip from '../../../../shared-components/ui/Tooltip';
import QuestionMark from '../../../../assets/QuestionMark';
import styles from './styles.module.scss';
import { useGetCart } from '../../../../hooks/useGetCart';
import calculateCartPrices from '../../../../utils/calculateCartPrices';
import { getClientCurrency } from '../../../../../shared/utils/storage';
import { ROUTES } from '../../../../../shared/constants';
import { OfferState } from '../../../../services/graphql';

type Props = {
    priceCrossed: number;
    mainPrice: number;
    nextPrice: number | undefined;
    nextLevel: number;
    showNextLevel?: boolean;
    showHintAfterTrade: boolean;
};

interface TooltipProps {
    level: number;
    discount: string;
    currency: string;
}

const TooltipButton = () => {
    const history = useHistory();
    return (
        <MainButton
            stylesType="lightGray"
            small
            text={t('10_addProducts')}
            action={() => {
                history.push(ROUTES.client.catalog);
            }}
        />
    );
};

const TooltipContent = ({ level, discount, currency }: TooltipProps) => {
    return (
        <div className={styles.TooltipContent}>
            <span
                className={styles.TooltipContent_text}
                dangerouslySetInnerHTML={{
                    __html: t('13_addProductsForDiscount', {
                        level,
                        discount: numberWithSpaces(discount),
                        currency,
                    }),
                }}
            ></span>
            <TooltipButton />
        </div>
    );
};

export const Price = ({
    priceCrossed,
    mainPrice,
    nextPrice,
    nextLevel,
    showNextLevel,
    showHintAfterTrade,
}: Props) => {
    const cart = useGetCart();

    const currency = getClientCurrency();
    const showCrossedPrice = priceCrossed > 0 && priceCrossed !== mainPrice;

    return (
        <div>
            <div>
                {showCrossedPrice ? (
                    <div className={styles.PriceCrossed}>{`${numberWithSpaces(
                        priceCrossed
                    )} ${currency}`}</div>
                ) : null}{' '}
                <div className={styles.MainPrice}>{`${numberWithSpaces(
                    mainPrice
                )} ${currency}`}</div>
            </div>
            {showNextLevel &&
            nextPrice !== undefined &&
            nextPrice !== mainPrice ? (
                <div className={styles.LevelText}>
                    {t('11_priceOn', {
                        price: numberWithSpaces(nextPrice),
                        currency,
                    })}
                    &nbsp;
                    <div
                        style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                        {t('15_priceOnLevel', {
                            level: nextLevel,
                        })}
                        <Tooltip
                            visibleOnInit={showHintAfterTrade}
                            content={
                                <TooltipContent
                                    level={nextLevel}
                                    discount={
                                        cart
                                            ? countDiscount({
                                                  products:
                                                      cart.cartProducts || [],
                                                  offerState:
                                                      cart.offerState as OfferState,
                                              }).toString()
                                            : '0'
                                    }
                                    currency={currency}
                                />
                            }
                        >
                            <div className={styles.LevelWrapper}>
                                <div className={styles.QuestionMark}>
                                    <QuestionMark />
                                </div>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
