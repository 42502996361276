import { useQuery } from '@apollo/client';
import { graphqlClientFront, LinkType } from '../clients';
import { useMemo } from 'react';
import { GET_CART } from '../../../New/services/graphql';
import { GetCartQuery } from './generated/graphql.types';

export const useGetCartQuery = (skip = false) => {
    const {
        loading: cartLoading,
        error: cartError,
        data: cartData,
        refetch: cartRefetch,
        previousData: cartPreviousData,
    } = useQuery<GetCartQuery>(GET_CART, {
        context: { linkType: LinkType.Client },
        client: graphqlClientFront,
        skip,
    });

    const cart = useMemo(() => cartData?.cart, [cartData]);

    return { cart, cartLoading, cartError, cartRefetch, cartPreviousData };
};
