export const getAxiosError = (
    error
): {
    type: 'NOT_FOUND' | 'INVALID_UUID' | 'COMMON' | 'INTERNAL_SERVER_ERROR';
    message: string;
    error: any;
} => {
    const errorResponse = 'response' in error ? error.response : error;

    if (errorResponse.data?.error?.message.includes('not found')) {
        return {
            type: 'NOT_FOUND',
            message: 'Is not possible to create a session. Cart not found',
            error,
        };
    }

    if (errorResponse.data?.error?.message.includes('invalid UUID')) {
        return {
            type: 'INVALID_UUID',
            message: 'Invalid identification params',
            error,
        };
    }

    if (errorResponse.status === 500) {
        return {
            type: 'INTERNAL_SERVER_ERROR',
            message: 'Internal error. Something went wrong',
            error,
        };
    }

    return {
        type: 'COMMON',
        message: 'Something went wrong',
        error,
    };
};
