import React, { ReactNode, SyntheticEvent } from 'react';
import s from './styles.module.scss';

interface Props {
    label: string | ReactNode;
    onChange: (value: string) => void;
    onClick?: (e: SyntheticEvent) => void;
    checked: boolean;
    value: string;
    style?: any;
}

const RadioInput = ({
    label,
    onChange,
    checked,
    value,
    style,
    onClick,
}: Props) => (
    <div style={{ ...style }}>
        <label className={s.RadioInput}>
            <input
                type="radio"
                value={value}
                checked={checked}
                onChange={(e) => onChange(e.target.value)}
                onClick={onClick}
            />
            <span />
            <div>{label}</div>
        </label>
    </div>
);

export default RadioInput;
