import { clientSession } from '../auth';

export const asyncLocalStorage = {
    async setItem(key: string, value: string) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    async getItem(key: string): Promise<string | null> {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    },
};

// Seller
export const setSellerSession = (session: string) => {
    localStorage.setItem('seller_session_id', session);
};

export const getSellerSession = () => {
    return localStorage.getItem('seller_session_id') || '';
};

export const removeSellerSession = () => {
    localStorage.removeItem('seller_session_id');
};

export const setEmptySession = (session: string) => {
    localStorage.setItem('empty_session_id', session);
};

export const getEmptySession = () => {
    return localStorage.getItem('empty_session_id') || '';
};

export const removeEmptySession = () => {
    localStorage.removeItem('empty_session_id');
};

// Askona
export const AsconaId = 'c713c9c3-7b43-47e8-b8bb-5f54027518ef';
export const AsconaId2Error = 'c713c9c3-7b43-47e8-b8bb-5f5402751nnn';
export const AsconaId2Ok = 'f1d1bdf0-1e0a-40cf-9f11-535cb0907a11';

export const setAsconaSession = (session: string) => {
    localStorage.setItem('ascona_session_id', session);
};

export const removeAsconaSession = () => {
    localStorage.removeItem('ascona_session_id');
};

export const getAsconaSession = () => {
    return localStorage.getItem('ascona_session_id') || '';
};

// Client
/**
 * @deprecated - для клиентской части нужно использовать useAuth()
 */
export const setClientSession = async (session: string) => {
    // TODO: временный хак, так как нужно обеспечить обратную совместимость с Seller https://trello.com/c/KVYiwdiR
    const clientSessionData = clientSession.get();
    await clientSession.set({
        ...clientSessionData,
        session_id: session,
    });

    await asyncLocalStorage.setItem('client_session_id', session);
    await new Promise((resolve) => setTimeout(resolve, 10));

    return Promise.resolve();
};

export const setShowScannerInCatalog = (val: boolean) => {
    localStorage.setItem('show_scanner_in_catalog', val ? 'true' : 'false');
};

export const setMonoCatalog = (val: boolean) => {
    localStorage.setItem('use_mono_catalog', val ? 'true' : 'false');
};

export const setShowPriceCross = (val: boolean) => {
    localStorage.setItem('should_show_price_cross', val ? 'true' : 'false');
};

export const getShowScannerInCatalog = () => {
    return localStorage.getItem('show_scanner_in_catalog') === 'true';
};

export const getMonoCatalog = () => {
    return localStorage.getItem('use_mono_catalog') === 'true';
};

export const getShowPriceCross = () => {
    return localStorage.getItem('should_show_price_cross') === 'true';
};

export const setClientCurrency = (currency: string) => {
    localStorage.setItem('client_currency', currency);
};

export const getClientCurrency = (): string => {
    return localStorage.getItem('client_currency') || 'RUB';
};

export const removeClientSession = () => {
    localStorage.removeItem('client_session_id');
};

// SuperAdmin
export const setAdminSession = (session: string) => {
    localStorage.setItem('admin_session_id', session);
};

export const removeAdminSession = () => {
    localStorage.removeItem('admin_session_id');
};

export const getAdminSession = () => {
    return localStorage.getItem('admin_session_id') || '';
};

export const clearLocalStorage = () => {
    localStorage.clear();
};

// FILTER STATES FOR NEW CATALOG
// Функция для сохранения состояния фильтра в localStorage с учетом shopId
export function saveFilterStateToLocalStorage(shopId, filterState) {
    try {
        const serializedState = JSON.stringify(filterState);
        const storageKey = `filterState_${shopId}`;
        localStorage.setItem(storageKey, serializedState);
    } catch (error) {
        console.error('Ошибка при сохранении состояния фильтра:', error);
    }
}

// Функция для извлечения состояния фильтра из localStorage по shopId
export function getFilterStateFromLocalStorage(shopId) {
    try {
        const storageKey = `filterState_${shopId}`;
        const serializedState = localStorage.getItem(storageKey);
        if (serializedState === null) {
            return null;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        console.error('Ошибка при извлечении состояния фильтра:', error);
        return null;
    }
}

// Функция для сохранения состояния категории в localStorage с учетом shopId и
export function saveCategoryStateToLocalStorage(shopId, categoryState_) {
    try {
        const serializedState = JSON.stringify(categoryState_);
        const storageKey = `categoryState_${shopId}`;
        localStorage.setItem(storageKey, serializedState);
    } catch (error) {
        console.error('Ошибка при сохранении состояния фильтра:', error);
    }
}

// Функция для извлечения состояния фильтра из localStorage по shopId
export function getCategoryStateFromLocalStorage(shopId) {
    try {
        const storageKey = `categoryState_${shopId}`;
        const serializedState = localStorage.getItem(storageKey);
        if (serializedState === null) {
            return null;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        console.error('Ошибка при извлечении состояния фильтра:', error);
        return null;
    }
}

export const setCatalogReload = (val: boolean) => {
    localStorage.setItem('catalog_reload', val ? 'true' : 'false');
};

export const getCatalogReload = () => {
    return localStorage.getItem('catalog_reload') === 'true';
};
