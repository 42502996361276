import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as CloseIconGray } from '../../shared/images/icons/close-gray.svg';
import { ReactComponent as SearchIcon } from '../../shared/images/icons/search.svg';
import s from './styles.module.scss';
import PercentageLoader from '../../New/shared-components/ui/Loaders/PercentageLoader';

interface Props<T> {
    onSelect: (value: T) => void;
    items: {
        value: T;
        label: string;
    }[];
    loading: boolean;
    onDebounceFinish: (value: string) => void;
}

export default function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const handler = setTimeout(async () => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [delay, value]);

    return debouncedValue;
}

function useOutsideClick(
    ref: React.RefObject<HTMLElement>,
    callback: () => void
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const SearchInput = <T extends unknown>({
    onSelect,
    items,
    onDebounceFinish,
    loading,
}: Props<T>) => {
    const [isActive, setIsActive] = useState(false);
    const [searchText, setSearchText] = useState('');
    const debouncedSearchValue = useDebounce<string>(searchText, 800);

    const ref = useRef(null);

    useOutsideClick(ref, () => setIsActive(false));

    useEffect(() => {
        if (debouncedSearchValue.length >= 2) {
            onDebounceFinish(debouncedSearchValue);
        }
    }, [debouncedSearchValue, onDebounceFinish]);

    const isEmptySearchAndItemsExist =
        debouncedSearchValue.length === 0 && items.length !== 0;
    const isSearchAndEmptyItems =
        debouncedSearchValue.length !== 0 && items.length === 0;
    const isAllEmpty = debouncedSearchValue.length === 0 && items.length === 0;

    return (
        <div className={s.dropdown} ref={ref}>
            <div className={s.dropdownBtn}>
                <SearchIcon className={s.searchIcon} />
                <input
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                        setIsActive(true);
                        setSearchText(e.target.value);
                    }}
                    onFocus={() => setIsActive(true)}
                    className={s.searchInput}
                />
                <div
                    className={s.closeIcon}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSearchText('');
                        setIsActive(false);
                    }}
                >
                    <CloseIconGray width="16" height="16" />
                </div>
            </div>
            <div
                className={s.dropdownContent}
                style={{ display: isActive ? 'block' : 'none' }}
            >
                {loading ? (
                    <div className={s.item}>
                        <PercentageLoader />
                    </div>
                ) : null}

                {(isEmptySearchAndItemsExist ||
                    isSearchAndEmptyItems ||
                    isAllEmpty) &&
                    !loading && <div className={s.item}>No items</div>}
                {(debouncedSearchValue.length === 0 || loading
                    ? []
                    : items
                ).map(({ value, label }) => (
                    <div
                        style={{ textAlign: 'left' }}
                        key={label}
                        onClick={() => {
                            onSelect(value);
                            setIsActive(!isActive);
                            setSearchText('');
                        }}
                        className={s.item}
                    >
                        {label}
                    </div>
                ))}
            </div>
        </div>
    );
};
