import { useMutation } from '@apollo/client';
import { GET_CART, PRODUCT_REMOVE } from '../services/graphql';
import { NotificationManager } from 'react-notifications';
import { t } from 'i18next';
import {
    CartProductRemoveMutation,
    CartProductRemoveMutationVariables,
} from '../../shared/graphql/client/generated/graphql.types';
import { handleMinusProductError } from '../../shared/errors/handleMinusProductError';

type Params = {
    onServerErrorCallback?: () => void;
    onCompleted?: () => void;
};

export const useMutationRemoveProduct = ({
    onCompleted,
    onServerErrorCallback,
}: Params = {}) =>
    useMutation<CartProductRemoveMutation, CartProductRemoveMutationVariables>(
        PRODUCT_REMOVE,
        {
            update: (cache, { data }) => {
                if (data) {
                    cache.writeQuery({
                        query: GET_CART,
                        data: { cart: data.cartProductRemove },
                    });
                }
            },
            onCompleted,
            onError(error) {
                handleMinusProductError(error, onServerErrorCallback);
            },
        }
    );
