import { useMutation } from '@apollo/client';
import { GET_CART, PRODUCT_MINUS } from '../services/graphql';
import {
    CartProductMinusMutation,
    CartProductMinusMutationVariables,
} from '../../shared/graphql/client/generated/graphql.types';
import { handleMinusProductError } from '../../shared/errors/handleMinusProductError';

type Params = {
    onServerErrorCallback?: () => void;
    onCompleted?: () => void;
};

export const useMutationProductMinus = ({
    onCompleted,
    onServerErrorCallback,
}: Params = {}) =>
    useMutation<CartProductMinusMutation, CartProductMinusMutationVariables>(
        PRODUCT_MINUS,
        {
            update: (cache, { data }) => {
                if (data) {
                    cache.writeQuery({
                        query: GET_CART,
                        data: { cart: data.cartProductMinus },
                    });
                }
            },
            onCompleted,
            onError(error) {
                handleMinusProductError(error, onServerErrorCallback);
            },
        }
    );
