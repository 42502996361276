import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { clearLocalStorage } from '../../../shared/utils/storage';
import { deleteCookies } from '../../../shared/utils/deleteCookies';

const SEARCH_PARAM_NEW_SESSION = 'newSession';

export const ClearSessionProvider = ({
    children,
}: PropsWithChildren<unknown>) => {
    const [isResolved, setIsResolved] = useState<boolean>(false);

    const history = useHistory();
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    useEffect(() => {
        if (!isResolved) {
            const isNewSession =
                searchParams.get(SEARCH_PARAM_NEW_SESSION) === 'true';

            if (isNewSession) {
                clearLocalStorage();
                deleteCookies();
            }

            searchParams.delete(SEARCH_PARAM_NEW_SESSION);
            history.replace({
                search: searchParams.toString(),
            });
            setIsResolved(true);
        }
    }, [isResolved, searchParams, setIsResolved]);

    return <React.Fragment>{isResolved ? children : null}</React.Fragment>;
};
