import React from 'react';
import '../global.css';
import '../shared/fonts/stylesheet.css';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

import clsx from 'clsx';
import common from '../shared/styles/common.module.scss';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import {
    ClearSessionProvider,
    ApolloProvider,
    TranslationProvider,
} from './providers';
import { ROUTES } from '../shared/constants';
import { getAdminSession, getSellerSession } from '../shared/utils/storage';
import Seller from '../Components/Seller';
import { Shop } from '../Components/Client';
import {
    AdminLayout,
    ClientLayout,
    SellerLayout,
    SuperAdminLayout,
} from './layouts';
import ErrorPage from '../Components/ErrorPage';
import Cart from '../New/pages/Cart';
import { QrReader as Scanner } from '../Components/QrReader';
import { CatalogNew } from '../Components/Catalog';
import QrCodePage from '../Components/QrCodePage';
import SellerAuth from '../Components/Seller/Auth';
import SuperAdminAuth from '../Components/SuperAdmin/Auth';
import { default as ShopSuperAdmin } from '../Components/SuperAdmin/Shop/Shop';
import SuperAdmin from '../Components/SuperAdmin';
import SignIn from '../Components/SignIn';
import ShopAdmin from '../Components/ShopAdmin';
import ShopAdminStatisticsSalesSummary from '../Components/ShopAdmin/Statistics/SalesSummary';
import ShopAdminStatisticsProductRatings from '../Components/ShopAdmin/Statistics/ProductRatings';
import { RootPage } from '../pages/root';
import { AuthProvider } from '../shared/auth';

const App = () => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');

    return (
        <div className={clsx(common.FullPage, common.Wrapper)}>
            <TranslationProvider>
                <ApolloProvider>
                    <Router>
                        <ClearSessionProvider>
                            <>
                                <Switch>
                                    {/* Client routes */}
                                    <Route
                                        path={[
                                            ROUTES.client.root,
                                            ROUTES.client.productId,
                                            ROUTES.client.cartId,
                                            ROUTES.client.shopId,
                                            ROUTES.client.catalog,
                                            ROUTES.client.errorPage,
                                            ROUTES.client.qrcode,
                                            ROUTES.client.scanCode,
                                        ]}
                                        exact
                                    >
                                        <ClientLayout>
                                            <AuthProvider>
                                                <Route
                                                    exact
                                                    path={ROUTES.client.root}
                                                >
                                                    <RootPage />
                                                </Route>
                                                <Route
                                                    exact
                                                    path={ROUTES.client.shopId}
                                                >
                                                    <Shop />
                                                </Route>
                                                <Route
                                                    exact
                                                    path={
                                                        ROUTES.client.productId
                                                    }
                                                >
                                                    {/* TODO: разделить cart & product https://trello.com/c/xqmRIiCK */}
                                                    <Cart />
                                                </Route>

                                                <Route
                                                    path={ROUTES.client.cartId}
                                                >
                                                    <Cart />
                                                </Route>
                                                <Route
                                                    path={ROUTES.client.catalog}
                                                >
                                                    <CatalogNew />
                                                </Route>
                                                <Route
                                                    path={
                                                        ROUTES.client.scanCode
                                                    }
                                                >
                                                    <Scanner />
                                                </Route>
                                                <Route
                                                    path={ROUTES.client.qrcode}
                                                >
                                                    <QrCodePage />
                                                </Route>
                                                <Route
                                                    exact
                                                    path={
                                                        ROUTES.client.errorPage
                                                    }
                                                >
                                                    <ErrorPage />
                                                </Route>
                                            </AuthProvider>
                                        </ClientLayout>
                                    </Route>

                                    {/* Selle routes */}
                                    <Route
                                        path={[
                                            ROUTES.seller.sellerAuth,
                                            ROUTES.seller.seller,
                                        ]}
                                        exact
                                    >
                                        <SellerLayout>
                                            <Route
                                                path={ROUTES.seller.sellerAuth}
                                                exact
                                            >
                                                <SellerAuth />
                                            </Route>
                                            <Route
                                                path={ROUTES.seller.seller}
                                                exact
                                                render={() =>
                                                    getSellerSession() ? (
                                                        <Seller />
                                                    ) : (
                                                        <Redirect
                                                            to={
                                                                ROUTES.seller
                                                                    .sellerAuth
                                                            }
                                                        />
                                                    )
                                                }
                                            />
                                        </SellerLayout>
                                    </Route>

                                    {/* Super admin routes */}
                                    <Route
                                        path={[
                                            ROUTES.superAdmin.superAdminAuth,
                                            ROUTES.superAdmin.superAdminShopId,
                                            ROUTES.superAdmin.superAdmin,
                                        ]}
                                        exact
                                    >
                                        <SuperAdminLayout>
                                            <Route
                                                exact
                                                path={
                                                    ROUTES.superAdmin
                                                        .superAdminAuth
                                                }
                                            >
                                                <SuperAdminAuth />
                                            </Route>
                                            <Route
                                                exact
                                                path={
                                                    ROUTES.superAdmin
                                                        .superAdminShopId
                                                }
                                                render={() =>
                                                    getAdminSession() ? (
                                                        <ShopSuperAdmin />
                                                    ) : (
                                                        <Redirect
                                                            to={
                                                                ROUTES
                                                                    .superAdmin
                                                                    .superAdminAuth
                                                            }
                                                        />
                                                    )
                                                }
                                            />
                                            <Route
                                                path={
                                                    ROUTES.superAdmin.superAdmin
                                                }
                                                exact
                                                render={() =>
                                                    getAdminSession() ? (
                                                        <SuperAdmin />
                                                    ) : (
                                                        <Redirect
                                                            to={
                                                                ROUTES
                                                                    .superAdmin
                                                                    .superAdminAuth
                                                            }
                                                        />
                                                    )
                                                }
                                            />
                                        </SuperAdminLayout>
                                    </Route>

                                    {/* Admin routes */}
                                    <Route
                                        exact
                                        path={[
                                            ROUTES.admin.signIn,
                                            ROUTES.admin.itemsAdmin,
                                            ROUTES.admin.statisticsSalesSummary,
                                            ROUTES.admin
                                                .statisticsProductRatings,
                                        ]}
                                    >
                                        <AdminLayout>
                                            <Route
                                                path={ROUTES.admin.signIn}
                                                exact
                                            >
                                                <SignIn />
                                            </Route>
                                            <Route
                                                exact
                                                path={ROUTES.admin.itemsAdmin}
                                                render={() =>
                                                    isAuthenticated ? (
                                                        <ShopAdmin />
                                                    ) : (
                                                        <Redirect
                                                            to={
                                                                ROUTES.admin
                                                                    .signIn
                                                            }
                                                        />
                                                    )
                                                }
                                            />

                                            <Route
                                                exact
                                                path={
                                                    ROUTES.admin
                                                        .statisticsSalesSummary
                                                }
                                            >
                                                <ShopAdminStatisticsSalesSummary />
                                            </Route>
                                            <Route
                                                exact
                                                path={
                                                    ROUTES.admin
                                                        .statisticsProductRatings
                                                }
                                            >
                                                <ShopAdminStatisticsProductRatings />
                                            </Route>
                                        </AdminLayout>
                                    </Route>

                                    <Route exact path="*">
                                        <ErrorPage />
                                    </Route>
                                </Switch>
                            </>
                        </ClearSessionProvider>
                    </Router>
                    <NotificationContainer />
                </ApolloProvider>
            </TranslationProvider>
        </div>
    );
};

export default App;
