import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import './index.css';
import reportWebVitals from './reportWebVitals';

dayjs.extend(customParseFormat);

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://3bd266b0ff194f68b5cecc3c35100e73@o1284518.ingest.sentry.io/6530066',
        integrations: [Sentry.browserTracingIntegration()],
        environment: process.env.NODE_ENV,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
