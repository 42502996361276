import React from 'react';
import styles from './styles.module.scss';
import { CartProduct } from '../../../../../shared/graphql/client/generated/graphql.types';

type Props = {
    product: CartProduct;
    onDecrease: () => void;
    onIncrease: () => void;
};
export const Minus = () => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9 15V17H23V15H9Z" fill="#171717" />
    </svg>
);

export const Plus = () => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M15 15V9H17V15H23V17H17V23H15V17H9V15H15Z" fill="#171717" />
    </svg>
);

const Count = ({ product, onDecrease, onIncrease }: Props) => {
    return (
        <div className={styles.count__container}>
            <div className={styles.count__minus} onClick={onDecrease}>
                <Minus />
            </div>
            <div>{product.count}</div>
            <div className={styles.count__plus} onClick={onIncrease}>
                <Plus />
            </div>
        </div>
    );
};

export default Count;
