import { ApolloError } from '@apollo/client/errors';
import { t } from 'i18next';
import { NotificationManager } from 'react-notifications';
import * as Sentry from '@sentry/react';

export const handleMinusProductError = (
    error: ApolloError,
    onServerErrorCallback?: () => void
) => {
    if (error.networkError?.name === 'ServerError') {
        onServerErrorCallback?.();
    } else if (error.message === 'error on products.GetByID: not found') {
        NotificationManager.error(t('Не удалось удалить товар'));
    } else if (error.message !== 'product already in cart') {
        NotificationManager.error(t('Не удалось удалить товар'));
        Sentry.captureException(error);
    }
};
