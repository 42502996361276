import React, { useState } from 'react';
import { OfferState } from '../../../../services/graphql';
import styles from './styles.module.scss';
import DeleteIcon from '../../../../shared-components/ui/DeleteIcon';
import { Price } from './Price';
import Count from './Count';
import RemoveItem from './RemoveItem';
import { countPrices } from '../../../../utils';
import { useMutationProductPlus } from '../../../../hooks/useMutationProductPlus';
import { useMutationProductMinus } from '../../../../hooks/useMutationProductMinus';
import { useMutationRemoveProduct } from '../../../../hooks/useMutationRemoveProduct';
import ProductSizes from './ProductSizes';
import { getMonoCatalog } from '../../../../../shared/utils/storage';
import {
    graphqlClientFront,
    LinkType,
} from '../../../../../shared/graphql/clients';
import { CartProduct } from '../../../../../shared/graphql/client/generated/graphql.types';

type Props = {
    product: CartProduct;
    showHintAfterTrade: boolean;
    offerState?: OfferState;
    targetLevel: number;
};

const ProductItem = ({
    product,
    showHintAfterTrade,
    offerState,
    targetLevel,
}: Props) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [mutateProductPlus] = useMutationProductPlus();
    const [mutateProductMinus] = useMutationProductMinus();
    const [mutateProductRemove] = useMutationRemoveProduct();

    const isMonoCatalog = getMonoCatalog();

    const { displayPrice, nextPrice, levelsElement, priceCross } = countPrices({
        product,
        offerState: offerState,
        current: Boolean(product.current),
    });

    return (
        <div className={styles['info-container']}>
            <div className={styles['info-image-wrapper']}>
                <img
                    className={styles.image}
                    src={product.imageUrl || ''}
                    alt="product"
                />
            </div>
            <div className={styles.info_wrapper}>
                <div className={styles['info__header-title']}>
                    <h3 className={styles['info-title']}>
                        {isMonoCatalog ? product.color : product.number}
                    </h3>
                    <div>
                        <DeleteIcon
                            onClick={() => {
                                setIsDeleteModalOpen(true);
                            }}
                        />
                    </div>
                </div>

                {!isMonoCatalog && product.sizes && (
                    <ProductSizes sizes={product.sizes} />
                )}

                <div className={styles['info-count-price-container']}>
                    <Price
                        // TODO add nextPriceWithDiscount
                        priceCrossed={priceCross}
                        mainPrice={displayPrice}
                        nextPrice={nextPrice}
                        nextLevel={targetLevel}
                        showNextLevel={levelsElement}
                        showHintAfterTrade={showHintAfterTrade}
                    />
                    <Count
                        product={product}
                        onDecrease={() => {
                            if (product.count === 1) {
                                setIsDeleteModalOpen(true);
                            } else {
                                mutateProductMinus({
                                    variables: { cartProductID: product.id },
                                    context: { linkType: LinkType.Client },
                                    client: graphqlClientFront,
                                });
                            }
                        }}
                        onIncrease={() => {
                            mutateProductPlus({
                                variables: { cartProductID: product.id },
                                context: { linkType: LinkType.Client },
                                client: graphqlClientFront,
                            });
                        }}
                    />
                </div>
            </div>
            {isDeleteModalOpen && (
                <RemoveItem
                    product={product}
                    onDelete={() => {
                        mutateProductRemove({
                            variables: { cartProductID: product.id },
                            context: { linkType: LinkType.Client },
                            client: graphqlClientFront,
                        });
                        setIsDeleteModalOpen(false);
                    }}
                    onSave={() => {
                        setIsDeleteModalOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default ProductItem;
