import React, { useState } from 'react';
import common from '../../shared/styles/common.module.scss';
import Button from '../../UiComponents/Button';
import { ReactComponent as CheckIcon } from '../../shared/images/icons/check.svg';
import s from './styles.module.scss';
import clsx from 'clsx';
import RadioInput from '../../UiComponents/RadioInput';
import { useMutation } from '@apollo/client';
import { CREATE_CART_REPORT } from '../../graphql';
import { NotificationManager } from 'react-notifications';
import { ReactComponent as CloseIcon } from '../../shared/images/icons/close.svg';
import { useTranslation } from 'react-i18next';
import PercentageLoader from '../../New/shared-components/ui/Loaders/PercentageLoader';
import { graphqlClientSeller, LinkType } from '../../shared/graphql/clients';

enum FeedbackType {
    All = 'all',
    Trade = 'trade',
    Price = 'price',
}

interface Props {
    cartId: string;
    onClose: () => void;
}

const FeedbackScreen = ({ cartId, onClose }: Props) => {
    const [reason, setReason] = useState('');
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const [mutateOnCreateReport] = useMutation(CREATE_CART_REPORT);

    const onCreateReport = () => {
        setLoading(true);
        mutateOnCreateReport({
            variables: { type: reason, text: comment, cartID: cartId },
            context: { linkType: LinkType.Seller },
            client: graphqlClientSeller,
        })
            .then(() => onClose())
            .catch((e) =>
                NotificationManager.error(
                    t('Не удалось сообщить о проблеме'),
                    undefined,
                    undefined,
                    () => alert(e)
                )
            )
            .finally(() => setLoading(false));
    };

    return (
        <div className={clsx(common.FullPage, s.Feedback)}>
            <div className={common.Wrapper}>
                <div
                    className={common.LoadingCover}
                    style={{ display: loading ? 'flex' : 'none' }}
                >
                    <PercentageLoader />
                </div>
                <button
                    style={{ top: 16, right: 16, zIndex: 1000 }}
                    className={clsx(common.CloseBtn)}
                    onClick={onClose}
                >
                    <CloseIcon />
                </button>
                <p className={s.Feedback__title}>{t('Сообщите о проблеме')}</p>
                <p className={s.Feedback__subTitle}>
                    {t('Что у вас случилось?')}
                </p>
                <div>
                    <RadioInput
                        label={t('Не работает торг')}
                        onChange={(value) => setReason(value)}
                        value={FeedbackType.Trade}
                        checked={reason === FeedbackType.Trade}
                    />
                    <br />
                    <RadioInput
                        label={t('Ничего не работает')}
                        onChange={(value) => setReason(value)}
                        value={FeedbackType.All}
                        checked={reason === FeedbackType.All}
                    />
                    <br />
                    <RadioInput
                        label={t('Не нравится цена')}
                        onChange={(value) => setReason(value)}
                        value={FeedbackType.Price}
                        checked={reason === FeedbackType.Price}
                    />
                </div>

                {reason && (
                    <textarea
                        value={comment}
                        onChange={(e) => setComment(() => e.target.value)}
                        rows={10}
                        placeholder={t(
                            'Пожалуйста, расскажите, что вас не устраивает. Мы сразу решим все проблемы!'
                        )}
                    />
                )}

                <div style={{ flex: 1 }} />

                <Button
                    text={t('Отправить')}
                    icon={<CheckIcon />}
                    disabled={!reason || !comment}
                    type={'black'}
                    className={clsx(s.Seller__Btn)}
                    action={onCreateReport}
                />
            </div>
        </div>
    );
};

export default FeedbackScreen;
