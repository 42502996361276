import React, { useState } from 'react';
import clsx from 'clsx';
import common from '../../shared/styles/common.module.scss';
import s from './styles.module.scss';
import Button from '../../UiComponents/Button';
import { ReactComponent as KeyIcon } from '../../shared/images/icons/key.svg';
import { useHistory } from 'react-router-dom';
import {
    removeSellerSession,
    setSellerSession,
} from '../../shared/utils/storage';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import PercentageLoader from '../../New/shared-components/ui/Loaders/PercentageLoader';
import { ROUTES } from '../../shared/constants';

const Auth = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [login, setLogin] = useState('');
    const [pwd, setPwd] = useState('');
    const [loadingSession, setLoadingSession] = useState(false);

    const loadSession = () => {
        setLoadingSession(true);
        removeSellerSession();
        axios
            .post(`${process.env.REACT_APP_API_PATH}salesman/login`, {
                username: login,
                password: pwd,
            })
            .then((resp) => {
                setSellerSession(resp.data.token);
                history.push(ROUTES.seller.seller);
            })
            .catch((e) => {
                NotificationManager.error(
                    t('Неверный логин или пароль'),
                    undefined,
                    undefined,
                    () => alert(e)
                );
            })
            .finally(() => {
                setLoadingSession(false);
            });
    };

    return (
        <div className={clsx(s.SellerAuth)}>
            <div
                className={common.LoadingCover}
                style={{ display: loadingSession ? 'flex' : 'none' }}
            >
                <PercentageLoader />
            </div>
            <p className={s.SellerAuth__title}>{t('Привет!')}</p>
            <div className={s.SellerAuth__container}>
                <label>
                    {t('логин')}
                    <br />
                    <input
                        type="email"
                        onChange={(e) => setLogin(() => e.target.value)}
                    />
                </label>
                <label>
                    {t('пароль')}
                    <br />
                    <input
                        type="password"
                        onChange={(e) => setPwd(() => e.target.value)}
                    />
                </label>
            </div>
            <Button
                text={t('Войти')}
                className={s.Seller__Btn}
                action={() => loadSession()}
                icon={<KeyIcon />}
                disabled={!login || !pwd}
                type={'black'}
            />
        </div>
    );
};

export default Auth;
