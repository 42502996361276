import React, { ReactNode } from 'react';
import clsx from 'clsx';
import s from './styles.module.scss';
import Button from '../Button';

interface Props {
    style?: any;
    cls?: string;
    type?: 'green' | 'orange';
    img?: string;
    bonus?: number;
    children?: ReactNode;
}

const CardSeller = ({ style, cls, type, img, children, bonus }: Props) => {
    const cardStyle = clsx(s.Card, cls, {
        [s.Card_green]: !type || type === 'green',
        [s.Card_red]: type === 'orange',
    });

    return (
        <div className={cardStyle} style={style}>
            <div className={s.Card__inner}>
                <img className={s.Card__img} src={img} alt="" />
                <div className={s.Card__content}>{children}</div>
                {bonus && (
                    <div className={s.Card__bonus}>
                        <Button type="bonus" text={bonus} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardSeller;
