import React, { createContext, PropsWithChildren, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    I18nContextInterface,
    LanguagesEnum,
    LanguagesType,
} from './I18n.types';
import {
    CLIENT_LANGUAGE_LOCALSTORAGE_KEY,
    CLIENT_LANGUAGES_LOCALSTORAGE_KEY,
} from './I18n.constants';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { el, en, es, ru } from './';

const getLanguage = () =>
    LanguagesEnum[
        localStorage.getItem(
            CLIENT_LANGUAGE_LOCALSTORAGE_KEY
        ) as unknown as LanguagesType
    ] || LanguagesEnum.ru;

const setLanguage = (language: LanguagesType) =>
    localStorage.setItem(CLIENT_LANGUAGE_LOCALSTORAGE_KEY, language);

const setLanguages = (languages: LanguagesType[]) => {
    localStorage.setItem(
        CLIENT_LANGUAGES_LOCALSTORAGE_KEY,
        JSON.stringify(languages)
    );
};

const getLanguages = (): LanguagesType[] => {
    return localStorage.getItem(CLIENT_LANGUAGES_LOCALSTORAGE_KEY)
        ? JSON.parse(
              localStorage.getItem(CLIENT_LANGUAGES_LOCALSTORAGE_KEY) ||
                  `[${LanguagesEnum.ru}]`
          )
        : [LanguagesEnum.ru];
};

void i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources: {
        en: {
            translation: en,
        },
        ru: {
            translation: ru,
        },
        es: {
            translation: es,
        },
        el: {
            translation: el,
        },
    },
    lng: getLanguage(),
    fallbackLng: 'ru',
    interpolation: {
        escapeValue: false,
    },
});

const I18nContextValue: I18nContextInterface = {
    getLanguage,
    setLanguages,
    getLanguages,
    setLanguage,
    language: getLanguage(),
    t: i18n.t,
    i18n: i18n,
    isRuLanguage: getLanguage() === LanguagesEnum.ru,
};

export const I18nContext =
    createContext<I18nContextInterface>(I18nContextValue);

export const useI18n = () => useContext(I18nContext);

export const I18nProvider = ({ children }: PropsWithChildren<unknown>) => {
    const { t, i18n } = useTranslation();
    const language = getLanguage();
    const isRuLanguage = getLanguage() === LanguagesEnum.ru;

    return (
        <I18nContext.Provider
            value={{ ...I18nContextValue, t, i18n, language, isRuLanguage }}
        >
            {children}
        </I18nContext.Provider>
    );
};
