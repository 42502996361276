import { OfferState } from '../services/graphql';
import type { CartProduct } from '../../shared/graphql/client/generated/graphql.types';

export function numberWithSpaces(value?: number | string): string {
    if (!value?.toString()) {
        return '';
    }
    const number = Number(value.toString().replace(/\s/g, ''));
    return new Intl.NumberFormat('ru-RU').format(number / 100).toString();
}

export const countPrices = ({
    product,
    offerState,
    current,
}: {
    product: CartProduct;
    offerState?: OfferState;
    current?: boolean;
}) => {
    let displayPrice = 0;
    let priceCross = 0;
    let nextPrice: number | undefined = undefined;
    let levelsElement = false;

    if (current) {
        if (!offerState) {
            displayPrice = Number(product.displayPrice);
            nextPrice = product.packPrice;
            priceCross = product.priceCross ?? 0;
        }
        if (offerState === OfferState.Agreed) {
            displayPrice = Number(product.displayPrice);
            nextPrice = product.packPrice;
            levelsElement = true;
            priceCross = product.priceCross
                ? Number(product.priceCross)
                : Number(product.price);
        }

        if (offerState === OfferState.Condition) {
            if (product.inCondition) {
                displayPrice = Number(product.price);
                nextPrice = product.displayPrice;
                levelsElement = true;
                priceCross = product.priceCross ?? 0;
            }

            if (!product.inCondition) {
                displayPrice = Number(product.displayPrice);
                nextPrice = product.packPrice;
                levelsElement = true;
                priceCross = product.priceCross
                    ? Number(product.priceCross)
                    : Number(product.price);
            }
        }
    } else {
        if (!offerState) {
            displayPrice = Number(product.displayPrice);
        }
        if (offerState === OfferState.Agreed) {
            displayPrice = Number(product.displayPrice);
            priceCross = product.priceCross
                ? Number(product.priceCross)
                : Number(product.price);
        }
        if (offerState === OfferState.Condition) {
            if (product.inCondition) {
                displayPrice = Number(product.price);
                nextPrice = product.displayPrice;
                levelsElement = true;
                priceCross = product.priceCross ?? 0;
            }

            if (!product.inCondition) {
                displayPrice = Number(product.displayPrice);
                priceCross = product.priceCross
                    ? Number(product.priceCross)
                    : Number(product.price);
            }
        }
    }

    return {
        displayPrice,
        nextPrice,
        levelsElement,
        priceCross,
    };
};

export const countDiscount = ({
    products,
    offerState,
}: {
    products: CartProduct[];
    offerState?: OfferState;
}) => {
    if (offerState === OfferState.Agreed) {
        const currentProduct = products.find((product) => product.current);

        if (currentProduct) {
            return (
                (Number(currentProduct.displayPrice) -
                    Number(currentProduct.packPrice)) *
                Number(currentProduct.count)
            );
        }

        return 0;
    }

    if (offerState === OfferState.Condition) {
        return products.reduce((accumulator, product) => {
            if (product.inCondition) {
                accumulator +=
                    (Number(product.price) - Number(product.displayPrice)) *
                    Number(product.count);
            }

            return accumulator;
        }, 0);
    }

    return 0;
};
