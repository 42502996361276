import React from 'react';
import s from './styles.module.scss';
import clsx from 'clsx';
import { FilterState, sizes } from './Catalog';
import SizesFilter from './SizesFilter';
import { useTranslation } from 'react-i18next';
import { NEW_ASCONA_ID } from '../../New/utils/constants';

interface Props {
    filterState: FilterState;
    setFilterState: (state: FilterState) => void;
    showSizes: boolean;
}

const Filter = ({ filterState, setFilterState, showSizes }: Props) => {
    const { t } = useTranslation();
    const shopId = localStorage.getItem('shop_id');
    return (
        <div>
            {shopId !== NEW_ASCONA_ID && (
                <div className={s.Filter}>
                    <div
                        className={clsx(s.Filter, s.Filter__sex, {
                            [s.Filter__sex_selected]: filterState.male,
                        })}
                        onClick={(e) =>
                            setFilterState({
                                ...filterState,
                                male: filterState.female
                                    ? !filterState.male
                                    : filterState.male,
                            })
                        }
                    >
                        {t('Мужское')}
                    </div>
                    <div
                        className={clsx(s.Filter, s.Filter__sex, {
                            [s.Filter__sex_selected]: filterState.female,
                        })}
                        onClick={(e) =>
                            setFilterState({
                                ...filterState,
                                female: filterState.male
                                    ? !filterState.female
                                    : filterState.female,
                            })
                        }
                    >
                        {t('Женское')}
                    </div>
                </div>
            )}
            {/* TODO unused! */}
            {showSizes && (
                <SizesFilter
                    sizes={sizes}
                    filterState={filterState}
                    onFilterSelect={(size) =>
                        setFilterState({
                            ...filterState,
                            size: filterState.size.includes(size)
                                ? filterState.size.filter(
                                      (item) => item !== size
                                  )
                                : [...filterState.size, size],
                        })
                    }
                />
            )}
        </div>
    );
};

export default Filter;
