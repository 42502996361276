import React from 'react';
import styles from './styles.module.scss';

type Props = {
    onClick?: () => void;
};

const DeleteIcon = ({ onClick }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className={styles['delete-icon']}
            onClick={onClick}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2829 5.18691C10.4026 5.0672 10.565 4.99994 10.7343 4.99994H14.011C14.1803 4.99994 14.3427 5.0672 14.4624 5.18691C14.5821 5.30663 14.6494 5.469 14.6494 5.63831V6.27668H10.0959V5.63831C10.0959 5.469 10.1632 5.30663 10.2829 5.18691ZM16.6494 5.63831V6.27668H18.1069L18.1234 6.27681H19.7453C20.2976 6.27681 20.7453 6.72453 20.7453 7.27681C20.7453 7.8291 20.2976 8.27681 19.7453 8.27681H19.1069V18.7453C19.1069 19.445 18.829 20.1161 18.3342 20.6109C17.8394 21.1057 17.1683 21.3836 16.4686 21.3836H8.27673C7.57699 21.3836 6.90591 21.1057 6.41112 20.6109C5.91633 20.1161 5.63836 19.445 5.63836 18.7453V8.27681H5C4.44772 8.27681 4 7.8291 4 7.27681C4 6.72453 4.44772 6.27681 5 6.27681H6.62188L6.63836 6.27668H8.09592V5.63831C8.09592 4.93857 8.37389 4.26749 8.86868 3.7727C9.36347 3.27791 10.0345 2.99994 10.7343 2.99994H14.011C14.7108 2.99994 15.3818 3.27791 15.8766 3.7727C16.3714 4.26749 16.6494 4.93857 16.6494 5.63831ZM7.63836 8.27681V18.7453C7.63836 18.9146 7.70562 19.0769 7.82534 19.1967C7.94506 19.3164 8.10743 19.3836 8.27673 19.3836H16.4686C16.6379 19.3836 16.8003 19.3164 16.92 19.1967C17.0397 19.0769 17.1069 18.9146 17.1069 18.7453V8.27681H7.63836ZM14.011 10.3731C14.5633 10.3731 15.011 10.8208 15.011 11.3731V16.2882C15.011 16.8405 14.5633 17.2882 14.011 17.2882C13.4587 17.2882 13.011 16.8405 13.011 16.2882V11.3731C13.011 10.8208 13.4587 10.3731 14.011 10.3731ZM11.7343 11.3731C11.7343 10.8208 11.2866 10.3731 10.7343 10.3731C10.182 10.3731 9.73431 10.8208 9.73431 11.3731V16.2882C9.73431 16.8405 10.182 17.2882 10.7343 17.2882C11.2866 17.2882 11.7343 16.8405 11.7343 16.2882V11.3731Z"
                fill="#171717"
                fillOpacity="0.5"
            />
        </svg>
    );
};

export default DeleteIcon;
