import React, { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import store from '../../../shared/redux/store';

export const SellerLayout = ({ children }: PropsWithChildren<unknown>) => (
    <Provider store={store}>
        <div style={{ display: 'none' }} data-test-id="seller-layout"></div>
        <>{children}</>
    </Provider>
);
