import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import s from './styles.module.scss';
import clsx from 'clsx';
import common from '../../shared/styles/common.module.scss';
import Button from '../Button';
import React, { ReactNode, useEffect } from 'react';

interface Props {
    className?: string;
    isOpen: boolean;
    children?: ReactNode;
    okText?: string;
    width?: number;
    cancelBtn?: boolean;
    cancelText?: string;
    onAccept?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
    title?: string | ReactNode;
    maxHeight?: number | string;
}

const BaseModal = ({
    isOpen,
    onClose,
    width,
    children,
    okText,
    cancelBtn,
    cancelText,
    onAccept,
    onCancel,
    className,
    title,
    maxHeight = 302,
}: Props) => {
    const { t } = useTranslation();

    const handleCancel = () => {
        onCancel?.();
        onClose?.();
    };

    useEffect(() => {
        const handleScroll = (event: Event) => {
            event.preventDefault();
        };

        if (isOpen) {
            document.body.style.overflow = 'hidden';
            window.addEventListener('scroll', handleScroll, { passive: false });
        } else {
            document.body.style.overflow = 'auto';
            window.removeEventListener('scroll', handleScroll);
        }

        return () => {
            document.body.style.overflow = 'auto';
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            className={clsx(s.Modal, className)}
            style={{
                overlay: {
                    zIndex: 1000,
                    display: 'flex',
                    backgroundColor: 'rgba(84, 84, 84, 0.75)',
                },
                content: {
                    position: 'static',
                    maxHeight,
                    top: 0,
                    bottom: 0,
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: width || '100%',
                },
            }}
        >
            {!!title && (
                <h5 className={clsx(common.Title, s.Title)}>{title}</h5>
            )}
            {children}
            <div className={s.Modal__controls}>
                {onAccept || onClose ? (
                    <Button
                        action={onAccept || onClose}
                        text={okText || t('Ок')}
                        type={cancelBtn ? 'light' : 'primary'}
                    />
                ) : null}
                {cancelBtn && (
                    <Button
                        action={handleCancel}
                        text={cancelText || t('Отмена')}
                    />
                )}
            </div>
        </Modal>
    );
};

export default BaseModal;
