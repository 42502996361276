import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import QRCode from 'qrcode.react';
import common from '../../shared/styles/common.module.scss';
import { ReactComponent as CloseBlackIcon } from '../../shared/images/icons/close-black.svg';
import { useHistory } from 'react-router-dom';
import s from './styles.module.scss';
import { useMutation } from '@apollo/client';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import { getAsconaSession, getSellerSession } from '../../shared/utils/storage';
import Button from '../../UiComponents/Button';
import { ReactComponent as LogoutIcon } from '../../shared/images/icons/logout.svg';
import Settings from '../../UiComponents/Settings';
import { Typography } from 'antd';
import PercentageLoader from '../../New/shared-components/ui/Loaders/PercentageLoader';
import { graphqlClientFront, LinkType } from '../../shared/graphql/clients';
import { useGetCartQuery } from '../../shared/graphql/client/useGetCartQuery';
import { ROUTES } from '../../shared/constants';
import { CREATE_ORDER } from '../../shared/graphql/client/graphql';

const QrCodePage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [qr, setQr] = useState();
    // TODO: переделать seller mode https://trello.com/c/KVYiwdiR
    const sellerMode =
        !!getSellerSession() &&
        // @ts-ignore
        !history.location.state?.from?.includes('seller');

    const { cart, cartLoading } = useGetCartQuery();

    const products = cart?.cartProducts?.map((cartProduct) => ({
        id: cartProduct?.id,
        sizes: cartProduct?.sizes,
    }));

    const cartId = cart?.id;

    const [pinWindowIsOpen, setPinWindowIsOpen] = useState(false);

    const [mutateOnCreateOrder] = useMutation(CREATE_ORDER, {
        variables: { products },
        context: { linkType: LinkType.Client },
        client: graphqlClientFront,
    });

    useEffect(() => {
        cart &&
            !cartLoading &&
            !getAsconaSession() &&
            mutateOnCreateOrder()
                .then((res) => setQr(res.data.createOrder?.id))
                .catch((e) =>
                    NotificationManager.error(
                        t('Не удалось получить QR-код'),
                        undefined,
                        undefined,
                        () => alert(e)
                    )
                )
                .finally(() => setIsLoading(false));
    }, [cart, cartLoading]);

    return (
        <>
            <div className={s.Header}>
                <button
                    onClick={() => {
                        history.goBack();
                    }}
                    className={s.CloseBtn}
                >
                    <CloseBlackIcon />
                </button>
            </div>
            {getAsconaSession() ? (
                <div className={s.Content}>
                    <h1
                        className={clsx(
                            s.Content__title,
                            s.Content__title_ascona
                        )}
                    >
                        {t('Сервис в стадии тестирования.')}
                        <br />
                        {t('Указанные цены не гарантируются')}
                    </h1>
                    {sellerMode && (
                        <Button
                            className={s.SellerBtn}
                            icon={<LogoutIcon />}
                            text={t('Выход')}
                            type={'black'}
                            action={() => setPinWindowIsOpen(true)}
                        />
                    )}
                </div>
            ) : (
                <div className={s.Content}>
                    <h1 className={s.Content__title}>
                        {t('Покажите QR-код кассиру')}
                    </h1>
                    {!cartLoading && !!qr ? (
                        <QRCode
                            size={250}
                            includeMargin
                            className={clsx('qr_code_wrapper', {
                                [s.QrCodeBlur]: cartLoading || isLoading,
                            })}
                            value={`@@VENT@@${qr}@@`}
                        />
                    ) : (
                        <PercentageLoader />
                    )}
                    {cartId ? (
                        <Typography.Text
                            className={s.CartId}
                            copyable={{ text: cartId, icon: 'Copy' }}
                        />
                    ) : null}
                    {sellerMode && (
                        <Button
                            className={s.SellerBtn}
                            icon={<LogoutIcon />}
                            text={t('Выход')}
                            type={'black'}
                            action={() => setPinWindowIsOpen(true)}
                        />
                    )}
                </div>
            )}

            {pinWindowIsOpen && (
                <Settings
                    closeButton={false}
                    withDivider={false}
                    withInput
                    title={t('Введите код доступа')}
                    onClose={() => setPinWindowIsOpen(false)}
                >
                    <input
                        className={common.Input}
                        autoFocus
                        type="tel"
                        onChange={(e) => {
                            e.target.value === '1991' &&
                                history.push(ROUTES.seller.seller);
                        }}
                    />
                </Settings>
            )}
        </>
    );
};

export default QrCodePage;
