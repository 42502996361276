import React from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES } from '../../shared/constants';
import { useAuth } from '../../shared/auth/Auth.context';
import { redirectToMainPage } from '../../shared/auth';
import { getSellerSession } from '../../shared/utils/storage';

export const RootPage = () => {
    const { shop_id } = useAuth();

    if (getSellerSession()) {
        // TODO: переделать seller mode https://trello.com/c/KVYiwdiR
        return <Redirect to={ROUTES.seller.seller} />;
    }

    if (shop_id) {
        return (
            <Redirect to={ROUTES.client.shopId.replace(':shopId', shop_id)} />
        );
    }

    redirectToMainPage();

    return null;
};
