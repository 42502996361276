import React from 'react';

const PercentageIcon = () => {
    return (
        <svg
            width="131"
            height="132"
            viewBox="0 0 131 132"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="131"
                height="131"
                transform="translate(0 0.5)"
                fill="url(#pattern0)"
            />
            <g clipPath="url(#clip0_1326_5207)">
                <rect
                    width="5.45833"
                    height="65.5"
                    rx="2.72917"
                    transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 90.5869 44.7715)"
                    fill="#FF4B2B"
                />
                <ellipse
                    cx="2.72917"
                    cy="10.9167"
                    rx="2.72917"
                    ry="10.9167"
                    transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 90.5869 44.7715)"
                    fill="#FF4B2B"
                />
                <ellipse
                    cx="2.72917"
                    cy="10.9167"
                    rx="2.72917"
                    ry="10.9167"
                    transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 59.71 75.6484)"
                    fill="#FF4B2B"
                />
                <circle
                    cx="10.9167"
                    cy="10.9167"
                    r="8.41667"
                    transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 96.376 81.4375)"
                    stroke="#FF4B2B"
                    strokeWidth="5"
                />
                <path
                    d="M44.109 44.6091C40.8221 47.896 40.8221 53.2251 44.109 56.512C47.396 59.7989 52.7251 59.7989 56.012 56.512C59.2989 53.2251 59.2989 47.896 56.012 44.6091C52.7251 41.3222 47.396 41.3222 44.109 44.6091Z"
                    stroke="#FF4B2B"
                    strokeWidth="5"
                />
            </g>
            <defs>
                <pattern
                    id="pattern0"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                >
                    <use />
                </pattern>
                <clipPath id="clip0_1326_5207">
                    <rect
                        width="87.3333"
                        height="87.3333"
                        fill="white"
                        transform="matrix(-1 0 0 1 109.166 22.333)"
                    />
                </clipPath>
                <image id="image0_1326_5207" width="1" height="1" />
            </defs>
        </svg>
    );
};

export default PercentageIcon;
