import gql from 'graphql-tag';

export const GET_CATALOG_BY_CATEGORY = gql`
    query catalogByCategory($limit: Int, $page: Int, $category: String) {
        aggregatedProducts(
            category: $category
            pagination: { limit: $limit, page: $page }
        ) {
            list {
                aggregationSign
                category
                color
                displayPrice
                priceCross
                imageUrl
                number
                price
                sex
                sizes
                getLevel
                fabric
                options
            }
            pagination {
                limit
                page
                total
            }
        }
    }
`;

export const GET_SIZES_BY_CATEGORY = gql`
    query GetSizesForCategory($category: String) {
        aggregatedProducts(category: $category) {
            sizes
        }
    }
`;

export const SEARCH_MODEL = gql`
    query SearchProductsByNumber($search: String, $limit: Int, $page: Int) {
        aggregatedProducts(
            search: $search
            pagination: { limit: $limit, page: $page }
        ) {
            numbers
            categories
            pagination {
                limit
                page
                total
            }
        }
    }
`;

export const ON_MODEL_SELECT = gql`
    query SearchProductsByNumberOnModelSelect(
        $number: String
        $limit: Int
        $page: Int
    ) {
        aggregatedProducts(
            number: $number
            pagination: { limit: $limit, page: $page }
        ) {
            numbers
            categories
            pagination {
                limit
                page
                total
            }
        }
    }
`;

export const GET_OLD_CATALOG_CATEGORIES = gql`
    query GetOldCatalogCategories {
        aggregatedProducts {
            categories
        }
    }
`;

export const GET_NEW_CATALOG_FILTERS = gql`
    query GetNewCatalogFilters(
        $number: String
        $category: String
        $size: String
    ) {
        aggregatedProducts(number: $number, category: $category, size: $size) {
            categories
            numbers
            sizes
            options
        }
    }
`;

export const GET_FILTER_CATEGORIES = gql`
    query GetNewCatalogFiltersCategories {
        aggregatedProducts {
            categories
        }
    }
`;

export const GET_FILTER_NUMBERS = gql`
    query GetNewCatalogFiltersNumbers($category: String) {
        aggregatedProducts(category: $category) {
            numbers
        }
    }
`;

export const GET_FILTER_SIZES = gql`
    query GetNewCatalogFiltersSized($number: String) {
        aggregatedProducts(number: $number) {
            sizes
        }
    }
`;

export const GET_CATALOG_BY_FILTER = gql`
    query GetProductsByFilter(
        $number: String
        $category: String
        $size: String
        $option: String
        $limit: Int
        $page: Int
    ) {
        aggregatedProducts(
            category: $category
            number: $number
            options: $option
            size: $size
            pagination: { limit: $limit, page: $page }
        ) {
            list {
                aggregationSign
                category
                color
                displayPrice
                priceCross
                imageUrl
                number
                price
                sex
                sizes
                getLevel
                fabric
                options
            }
            pagination {
                limit
                page
                total
            }
        }
    }
`;
export const GET_CATALOG_BY_FILTER_LAZY_UPD = gql`
    query GetProductsByFilterLazy(
        $number: String
        $category: String
        $size: String
        $option: String
        $limit: Int
        $page: Int
    ) {
        aggregatedProducts(
            category: $category
            number: $number
            options: $option
            size: $size
            pagination: { limit: $limit, page: $page }
        ) {
            list {
                aggregationSign
                displayPrice
                priceCross
                price
            }
            pagination {
                limit
                page
                total
            }
        }
    }
`;

export const CREATE_ORDER = gql`
    mutation createOrder($products: [Products!]!) {
        createOrder(products: $products) {
            id
        }
    }
`;
