import { useMutation } from '@apollo/client';
import { GET_CART, PRODUCT_PLUS } from '../services/graphql';
import {
    CartProductPlusMutation,
    CartProductPlusMutationVariables,
} from '../../shared/graphql/client/generated/graphql.types';
import { handleAddProductError } from '../../shared/errors/handleAddProductError';

type Params = {
    onServerErrorCallback?: () => void;
    onCompleted?: () => void;
};

export const useMutationProductPlus = ({
    onCompleted,
    onServerErrorCallback,
}: Params = {}) =>
    useMutation<CartProductPlusMutation, CartProductPlusMutationVariables>(
        PRODUCT_PLUS,
        {
            update: (cache, { data }) => {
                if (data) {
                    cache.writeQuery({
                        query: GET_CART,
                        data: { cart: data.cartProductPlus },
                    });
                }
            },
            onCompleted,
            onError(error) {
                handleAddProductError(error, onServerErrorCallback);
            },
        }
    );
