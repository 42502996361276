import React, { useEffect, useRef, useState } from 'react';
import s from './QrReader.module.scss';
import clsx from 'clsx';
import common from '../../shared/styles/common.module.scss';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../shared/images/icons/close-white.svg';
import QrScanner from 'qr-scanner';
import { ROUTES } from '../../shared/constants';

export const QrReader = () => {
    const history = useHistory();

    const handleScan = (data: string) => {
        const searchWord = 'product/';
        if (data && data.includes(searchWord)) {
            const productId = data.slice(
                data.indexOf(searchWord) + searchWord.length
            );
            history.push(ROUTES.client.productId.replace(':id', productId));
        }
    };

    // QR States
    const scanner = useRef<QrScanner>();
    const videoEl = useRef<HTMLVideoElement>(null);
    const [qrOn, setQrOn] = useState<boolean>(true);

    // Success
    const onScanSuccess = (result: QrScanner.ScanResult) => {
        handleScan(result?.data);
    };

    useEffect(() => {
        if (videoEl?.current && !scanner.current) {
            // 👉 Instantiate the QR Scanner
            scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
                // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
                preferredCamera: 'environment',
            });

            // 🚀 Start QR Scanner
            scanner?.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        // 🧹 Clean up on unmount.
        // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
        return () => {
            if (!videoEl?.current) {
                scanner?.current?.stop();
            }
        };
    }, []);

    // ❌ If "camera" is not allowed in browser permissions, show an alert.
    useEffect(() => {
        if (!qrOn)
            alert(
                'Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.'
            );
    }, [qrOn]);

    return (
        <div className={s.ScanCodeContainer}>
            <header className={clsx(common.Header, s.Header)}>
                Наведите на QR-код
                <button
                    className={common.CloseBtn}
                    onClick={() => history.goBack()}
                >
                    <CloseIcon />
                </button>
            </header>

            <div className={s.ScanCodeContainer__qrScanner}>
                <video ref={videoEl}></video>
            </div>
        </div>
    );
};
