import React, { ReactNode } from 'react';
import clsx from 'clsx';
import common from '../../shared/styles/common.module.scss';
import s from './styles.module.scss';
import { ReactComponent as ArrowIcon } from '../../shared/images/icons/arrow.svg';
import Button from '../Button';
import { ReactComponent as CloseBlackIcon } from '../../shared/images/icons/close-black.svg';
import PercentageLoader from '../../New/shared-components/ui/Loaders/PercentageLoader';

interface Props {
    title?: string;
    closeButton?: boolean;
    withBackBtn?: boolean;
    withDivider?: boolean;
    withInput?: boolean;
    onClose?: () => void;
    isLoading?: boolean;
    children?: ReactNode;
}

const Settings = ({
    title,
    onClose,
    withBackBtn = true,
    withDivider = true,
    withInput,
    isLoading,
    closeButton = true,
    children,
}: Props) => (
    <div className={clsx(common.FullPage, s.Settings)}>
        <div className={clsx(common.Wrapper)}>
            <div
                className={common.LoadingCover}
                style={{ display: isLoading ? 'flex' : 'none' }}
            >
                <PercentageLoader />
            </div>

            <div className={s.Settings__Header}>
                {title}
                {closeButton && (
                    <button onClick={onClose} className={common.CloseBtn}>
                        <CloseBlackIcon />
                    </button>
                )}
            </div>

            <div className={s.Settings__Content}>{children}</div>

            {!withInput && <div style={{ flex: 1 }} />}
            {withBackBtn && (
                <div
                    className={clsx(s.Settings__Footer, {
                        [s.Settings__Footer_divider]: withDivider,
                        [s.Settings__Footer_input]: withInput,
                    })}
                >
                    <Button
                        type="black"
                        icon={<ArrowIcon />}
                        text={'Назад'}
                        action={onClose}
                    />
                </div>
            )}
        </div>
    </div>
);

export default Settings;
