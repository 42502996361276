import React from 'react';
import { Form, Input, Button, notification } from 'antd';
import s from './styles.module.scss';
import { api } from '../../api';
import { setToken } from '../../shared/utils/token';

const SignIn = () => {
    const handleSubmit = async (values) => {
        try {
            const { data } = await api.login(values);
            if (data.token) {
                setToken(data.token);
                localStorage.setItem('shop_id', data.shop_id);
                localStorage.setItem('isAuthenticated', 'true');
                window.location.pathname = '/axaxax36ax/items-admin';
            }
        } catch (e) {
            notification.error({
                // @ts-ignore
                message: e.data?.error?.message || 'Неверные данные',
            });
        }
    };

    return (
        <div className={s.Container}>
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Логин"
                    name="username"
                    rules={[
                        { required: true, message: 'Пожалуйста введите логин' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите пароль',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    wrapperCol={{ offset: 8, span: 16 }}
                    className={s.Controls}
                >
                    <Button type="primary" htmlType="submit">
                        Войти
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default SignIn;
