import { gql } from '@apollo/client';
import { GetCartQuery } from '../../../../../shared/graphql/client/generated/graphql.types';

export type CartProductAddByAggregationSignResponse = {
    cartProductAddByAggregationSign: GetCartQuery['cart'];
};

export const PRODUCT_ADD_BY_AGGREGATION_SIGN = gql`
    mutation cartProductAddByAggregationSign($aggregationSign: String!) {
        cartProductAddByAggregationSign(aggregationSign: $aggregationSign) {
            id
            cartProducts {
                color
                current
                packPrice
                count
                inCondition
                displayPrice
                id
                imageUrl
                number
                price
                sizes
                countTotal
                priceCross
            }
            level
            attempts
            offerState
            goodOffer
            betterOffer
            tradeComplete
            targetLevel
            availableAttempts
        }
    }
`;
