import React from 'react';
import styles from './styles.module.scss';
import { normalizeSizes } from '../../../../../shared/utils/normalizeSizes';

const CatalogSizes = ({ sizes }: { sizes: string[] | undefined }) => {
    return (
        <div className={styles.CatalogItem__sizes}>
            {sizes && normalizeSizes(sizes).join(' ')}
        </div>
    );
};

export default CatalogSizes;
