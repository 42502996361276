import { ApolloProvider as ApolloClientProvider } from '@apollo/client';
import React, { PropsWithChildren } from 'react';
import { graphqlClientFront } from '../../../shared/graphql/clients';

export const ApolloProvider = ({ children }: PropsWithChildren<unknown>) => {
    return (
        <ApolloClientProvider client={graphqlClientFront}>
            {children}
        </ApolloClientProvider>
    );
};
