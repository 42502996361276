import React from 'react';
import styles from './styles.module.scss';

type Props = {
    onClose: () => void;
};

const CloseButton: React.FC<Props> = ({ onClose }: Props): JSX.Element => {
    return (
        <div
            className={styles.close}
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onClose();
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <g clipPath="url(#clip0_1653_1493)">
                    <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="#7B7B7B"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1653_1493">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default CloseButton;
