import React from 'react';
import common from '../../shared/styles/common.module.scss';
import { Button, Layout, List } from 'antd';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { removeAdminSession } from '../../shared/utils/storage';
import s from './styles.module.scss';
import PercentageLoader from '../../New/shared-components/ui/Loaders/PercentageLoader';
import {
    graphqlClientSuperAdmin,
    LinkType,
} from '../../shared/graphql/clients';
import { ROUTES } from '../../shared/constants';
import { ADMIN_GET_SHOPS } from '../../shared/graphql/superadmin/graphql';
import {
    GetShopsQuery,
    GetShopsQueryVariables,
    Shops,
} from '../../shared/graphql/superadmin/generated/graphql.types';

const SuperAdmin = () => {
    const options = {
        context: { linkType: LinkType.SuperAdmin },
        client: graphqlClientSuperAdmin,
    };
    const { loading, data } = useQuery<GetShopsQuery, GetShopsQueryVariables>(
        ADMIN_GET_SHOPS,
        options
    );
    const history = useHistory();

    if (loading) {
        return (
            <div
                className={common.LoadingCover}
                style={{ display: loading ? 'flex' : 'none' }}
            >
                <PercentageLoader />
            </div>
        );
    }

    const { Header, Content } = Layout;

    return (
        <Layout className={common.AdminPage} style={{ padding: 0 }}>
            <Header className={s.Shop__header}>
                <span>{'Магазины'}</span>
                <Button
                    type="text"
                    icon={<LogoutOutlined />}
                    size="large"
                    onClick={() => {
                        removeAdminSession();
                        history.push(ROUTES.superAdmin.superAdminAuth);
                    }}
                >
                    Выход
                </Button>
            </Header>
            <Content className={s.Shop__content}>
                <List
                    size="large"
                    bordered
                    style={{ cursor: 'pointer' }}
                    dataSource={
                        [...(data?.shops || [])].sort(
                            (a, b) => a.title?.localeCompare(b.title || '') || 0
                        ) || []
                    }
                    renderItem={(item: Shops) => (
                        <List.Item
                            onClick={() =>
                                item.id &&
                                history.push(
                                    ROUTES.superAdmin.superAdminShopId.replace(
                                        ':shopId',
                                        item.id
                                    )
                                )
                            }
                        >
                            {item.title}
                        </List.Item>
                    )}
                />
            </Content>
        </Layout>
    );
};

export default SuperAdmin;
